import { Box, Button, Radio, styled } from "@mui/material";
import { forMobile } from "../../styles/adaptivity";

export const CustomBox = styled(Box)(() => ({
   background: '#2E3552',
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 516,
   height: 'auto',
   padding: 24,
   border: 'none',
   borderRadius: 16,
   [forMobile]: {
      width: '98%',
   }
  }));

  export const CustomRadio = styled(Radio)(() => ({
   position: 'relative',
   color: '#464F74',
   '&.Mui-checked': {
     color: '#39CA88',
   },
   '& > span:first-of-type::before': {
     content: "''",
     position: 'absolute',
     top: 4,
     left: 4,
     background: '#161A2B',
     width: 16,
     height: 16,
     borderRadius: '50%',
   },
 }));

  
  export const ButtonStyled = styled(Button)<{width?: number | string}>(({width}) => ({
    padding: 19,
    width: width,
    textTransform: 'initial',
    borderRadius: 12,
    color: '#FFF',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '-0.8px',
    lineHeight: 'normal',
   
   '&.MuiButton-outlined': {
     border: '1px solid rgba(255, 255, 255, 0.34)'
   },

   '&.Mui-disabled': {
    color: '#FFF',
    cursor: 'not-allowed',
    pointerEvents: 'auto',
   },

   '&.MuiButton-contained': {
    background: '#39CA88',
    border: 'none',
    boxShadow: 'none',
   }
}))