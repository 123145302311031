import { FC, memo } from "react";
import { Wrapper } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Typography } from "@mui/material";
import { FlexCenteredVertically } from "../../../../styles/styled-component";
import { useIsMobile } from "../../../../utils/use-is-mobile";
import { Circle } from "../../../../assets/tsx/circle";
import uuid from "react-uuid";

const keys = Array.from({ length: 20 }, () => uuid());

const Join: FC = memo(() => {
  const isMobile = useIsMobile();
  return (
    <Wrapper>
      <Swiper
        speed={3000}
        loop={true}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 15,
            spaceBetween: 150,
          },
        }}
        watchSlidesProgress
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
          waitForTransition: true,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {keys.map((key, i) => (
          <div key={key}>
            <SwiperSlide key={`${key}-slide1`}>
              <Typography
                color="#FFF"
                fontSize={isMobile ? "56px" : "113px"}
                letterSpacing={isMobile ? "-2.8px" : "-5.65px"}
                lineHeight="109%"
                sx={{ opacity: i % 2 !== 0 ? 1 : 0.4, whiteSpace: "nowrap" }}
              >
                Join our Beta
              </Typography>
            </SwiperSlide>
            <SwiperSlide key={`${key}-slide2`}>
              <FlexCenteredVertically>
                <Circle />
              </FlexCenteredVertically>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </Wrapper>
  );
});

export default Join;
