import { styled } from "@mui/material";
import { FlexCenteredVertically } from "../../styles/styled-component";
import bg from '../../assets/png/404.png'
import group from '../../assets/svg/bg-group.svg'
import { forMobile } from "../../styles/adaptivity";


export const Wrapper = styled('div')(() => ({
    backgroundImage: `url(${bg})`,
    height: '100vh'
}))

export const Content = styled(FlexCenteredVertically)(() => ({
    flexDirection: 'column',
    backgroundImage: `url(${group})`,
    height: '100vh',
    backgroundPosition: 'center 210px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: 192,
    position: 'relative',

    [forMobile]: {
        paddingTop: 141, 
    }
}))