import { FC, memo } from "react";
import { Position, Positions, PositionsWrapper } from "./styled";
import { DecorLine } from "../home/styled";
import { Line } from "../../assets/tsx/line";
import { Badge } from "../../components/badge";
import { Typography } from "@mui/material";
import { useIsMobile } from "../../utils/use-is-mobile";
import { FlexCenteredVertically } from "../../styles/styled-component";
import LazyImage from "../../components/lazy-image";
import { PagesPath } from "../../utils/route";
import arrow from "../../assets/svg/arrow-up-right.svg";
import { useNavigate } from "react-router-dom";

const careersEngineering = [
  {
    link: "Senior-Full-Stack",
    position: "Senior Full Stack",
    type: "Full Time",
    locate: "Remote",
    desc: "3+ years of development experience. Experience in leading development teams",
  },
  {
    link: "Senior-Backend-Developer",
    position: "Senior Backend Developer",
    type: "Full Time",
    locate: "Remote",
    desc: "Work experience with Node.js, Express.js, TypeScript backend. Hands-on experience with relational databases, NoSQL databases",
  },
  {
    link: "Senior-Frontend-Developer",
    position: "Senior Frontend Developer",
    type: "Full Time",
    locate: "Remote",
    desc: "Work experience as a Front-end developer for more than 4 years. Hands-on experience with React, Redux, and JavaScript",
  },
  {
    link: "Data-Scientist",
    position: "Data Scientist",
    type: "Full Time",
    locate: "Remote",
    desc: "Experience as a Data Scientist or Data Analyst more than 2 years. Knowledge of SQL and Python",
  },
  {
    link: "Middle-Node.js-Developer-(Backend)",
    position: "Middle Node.js Developer (Backend)",
    type: "Full Time",
    locate: "Remote",
    desc: "Work experience with Node.js, Express.js. Hands-on experience with relational databases, NoSQL databases",
  },
  {
    link: "Manual-QA-(Middle-level)",
    position: "Manual QA (Middle level)",
    type: "Full Time",
    locate: "Remote",
    desc: "Experience as a Quality Assurance Tester for more than 3 years. Experience in project management and QA methodology",
  },
];

export const CareersPositions: FC = memo(() => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <PositionsWrapper id="positions">
      <DecorLine sx={{ height: 496, left: "5%", top: "-13%" }} delay={3}>
        <Line height={161} style={{ position: "relative", top: "60%" }} />
      </DecorLine>

      <DecorLine sx={{ height: 496, right: "5%", top: "63%" }} delay={3}>
        <Line height={161} style={{ position: "relative", top: "60%" }} />
      </DecorLine>

      <Badge text="JOIN OUR TEAM" type="primary" size="medium" />
      <Typography
        variant="h3"
        fontSize={isMobile ? "32px" : "48px"}
        fontWeight={500}
        letterSpacing={isMobile ? "-1.92px" : "-2.88px"}
        lineHeight="101%"
        marginTop="20px"
      >
        Current Openings
      </Typography>

      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        lineHeight="normal"
        marginTop="70px"
        sx={{ opacity: 0.5 }}
        textTransform="uppercase"
      >
        Engineering
      </Typography>

      <Positions>
        {careersEngineering.map((i) => (
          <Position
            key={i.position}
            onClick={() => navigate(`${PagesPath.careers}/${i.link}`)}
          >
            <div>
              <FlexCenteredVertically gap="8px" flexWrap="wrap">
                <Typography
                  fontSize="20px"
                  fontWeight={500}
                  letterSpacing="-1px"
                  marginRight="4px"
                  width={isMobile ? "100%" : "auto"}
                >
                  {i.position}
                </Typography>

                <Badge text={i.type} type="primary" size="small" />
                <Badge text={i.locate} type="secondary" size="small" />
              </FlexCenteredVertically>

              <Typography
                variant="body1"
                fontWeight={400}
                letterSpacing="-0.48px"
                maxWidth="432px"
                sx={{ opacity: 0.6 }}
                lineHeight="140%"
                marginTop="11px"
              >
                {i.desc}
              </Typography>
            </div>

            <FlexCenteredVertically gap="6px">
              <Typography
                color="#39CA88"
                fontSize="20px"
                fontWeight={700}
                letterSpacing="-1px"
                textTransform="uppercase"
              >
                Apply
              </Typography>
              <LazyImage src={arrow} alt="arrow" />
            </FlexCenteredVertically>
          </Position>
        ))}
      </Positions>
    </PositionsWrapper>
  );
});
