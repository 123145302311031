import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetStrategiesRes } from "./type";

export const gammaApi = createApi({
	reducerPath: "gammaApi",
	baseQuery: fetchBaseQuery({ baseUrl: "https://api.gammaprime.fi/v1" }),
	endpoints: (builder) => ({
		getStrategies: builder.query<GetStrategiesRes, null>({
			query: () => `/strategies`,
		}),
	}),
});

export const { useGetStrategiesQuery } = gammaApi;
