import { Typography, styled } from "@mui/material";
import { forMobile } from "../../../../styles/adaptivity";
import { FlexCenteredVertically } from "../../../../styles/styled-component";

export const Wrapper = styled(FlexCenteredVertically)(() => ({
    flexDirection: 'column',
    maxWidth: 1312,
    margin: '266px auto 152px',
    width: '100%',
    position: 'relative',

    [forMobile]: {
        margin: '80px auto 80px',
        padding: '0 16px',
    }
}))

export const Title = styled(Typography)(() => ({
    marginTop: 14,
    '& span': {
        color: '#464758'
    },
    [forMobile]: {
        marginTop: 20, 
    }
}))