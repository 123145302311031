import { Typography, styled } from "@mui/material";
import { forMobile } from "../../styles/adaptivity";

export const Wrapper = styled('div')(() => ({
    maxWidth: 1312,
    width: '100%',
    margin: '0 auto',
    paddingTop: 117,
    

    [forMobile]: {
        padding: '0 16px',
        marginTop: 40,
        marginBottom: 50,
    }
}))


export const FormWrapper = styled('div')(() => ({
    borderRadius: 16,
    border: '1px solid #373737',
    maxWidth: 424,
    width: '100%',
    padding: '46px 40px 33px',
    zIndex: 2,
    background: '#08091D',
}))

export const TypographyStyled = styled(Typography)(() => ({
    '& li': {
        marginLeft: 15
    }
}))