import React, { FC, memo } from "react";
import { FlexCenteredVertically } from "../../../styles/styled-component";
import { Typography, styled } from "@mui/material";
import LineChartUp from "../../../assets/svg/line-chart-up.svg";
import CreditCardShield from "../../../assets/svg/credit-card-shield.svg";
import BarChartCircle from "../../../assets/svg/bar-chart-circle.svg";
import CurrencyDollarCircle from "../../../assets/svg/currency-dollar-circle.svg";
import CoinsHand from "../../../assets/svg/coins-hand.svg";
import Globe from "../../../assets/svg/globe.svg";
import Scales from "../../../assets/svg/scales.svg";
import PiggyBank from "../../../assets/svg/piggy-bank.svg";
import { Circle } from "../styled";
import { forMobile } from "../../../styles/adaptivity";

const data = [
  { text: "Unique investment opportunities", icon: LineChartUp },
  {
    text: "Seamless access to structured product derivatives",
    icon: CreditCardShield,
  },
  {
    text: "Substantially more Alpha than traditional markets and ETFs",
    icon: BarChartCircle,
  },
  {
    text: "Combine options, futures, and bilateral OTC contracts",
    icon: CurrencyDollarCircle,
  },
  { text: "Managed directionality and volatility", icon: CoinsHand },
  {
    text: "Long-term exposure to crypto, including BTC and ETH",
    icon: CoinsHand,
  },
  { text: "Defined risk parameters and returns", icon: Globe },
  { text: "Predictable and superior return profiles", icon: Scales },
  {
    text: "Enhanced portfolios with uncorrelated diversification",
    icon: PiggyBank,
  },
];

export const TypographyStyled = styled(Typography)(() => ({
  "& span": {
    color: "#39CA88",
  },
}));

export const Cards = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  marginTop: 57,
  gap: 16,
  maxWidth: 1086,
  [forMobile]: {
    gridTemplateColumns: "1fr",
  },
}));

export const Card = styled("div")(() => ({
  borderRadius: 11,
  border: "1px solid rgba(255, 255, 255, 0.16)",
  padding: 14,
  display: "grid",
  gap: 16,
}));

export const Icon = styled("div")(() => ({
  background: "rgba(57, 202, 136, 0.80)",
  boxShadow: "0px 0px 40px 0px rgba(57, 202, 136, 0.40)",
  borderRadius: "50%",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const DecadesOfTradfi: FC = memo(() => {
  return (
    <FlexCenteredVertically
      flexDirection="column"
      padding="0 16px"
      marginTop="135px"
      position="relative"
    >
      <Circle sx={{ right: "-75%", top: "1%" }} />

      <Circle sx={{ left: "-75%", top: "1%" }} />
      <TypographyStyled
        color="#FFF"
        fontSize={48}
        letterSpacing={-2.88}
        lineHeight="101%"
        maxWidth={545}
        textAlign="center"
        fontWeight={500}
      >
        Decades of TradFi Experience: <span>Now in Web3</span>
      </TypographyStyled>

      <Typography
        maxWidth={413}
        color="#FFF"
        variant="body1"
        mt={"20px"}
        fontWeight={400}
        textAlign="center"
        sx={{ opacity: 0.6 }}
        letterSpacing={-0.48}
        lineHeight={"140%"}
      >
        We reviewed DeFi. Thought it was crazy. Tons of strategies to create
        Real Yield.
      </Typography>
      <Cards>
        {data.map((el) => (
          <Card key={el.text}>
            <Icon>
              <img src={el.icon} alt="icon" />
            </Icon>
            <Typography>{el.text}</Typography>
          </Card>
        ))}
      </Cards>
    </FlexCenteredVertically>
  );
});
