import React, { FC, memo } from "react";
import { Helmet } from "react-helmet";

interface IProps {
  url: string;
}

export const HelmetTags: FC<IProps> = memo(({ url }) => {
  return (
    <Helmet>
      <link rel="canonical" href={url} />
    </Helmet>
  );
});
