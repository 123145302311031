import { styled } from "@mui/material";

type PropsVertical = {
	height?: number;
	type: "purple" | "green";
	rotate?: string;
};

export const GradientLineVertical = styled("div")<PropsVertical>(({ height, type, rotate }) => ({
	height: height ?? 38,
	width: 1,
	background:
		type === "purple"
			? `linear-gradient(180deg, #D384F8 0%, rgba(17, 44, 48, 0) 100%);`
			: `linear-gradient(180deg, #06FFE1 0%, rgba(17, 44, 48, 0) 100%);
`,
	rotate: rotate ?? "0deg",
}));

type PropsHorizontal = {
	width?: number;
	type: "purple" | "green";
	rotate?: string;
};

export const GradientLineHorizontal = styled("div")<PropsHorizontal>(({ width, type, rotate }) => ({
	height: 1,
	width: width ?? 38,
	background:
		type === "purple"
			? `linear-gradient(270deg, #D384F8 0%, rgba(17, 44, 48, 0) 100%);`
			: `linear-gradient(270deg, #06FFE1 0%, rgba(17, 44, 48, 0) 100%);`,
	rotate: rotate ?? "0deg",
}));

export const GradientWrap = styled("div")<{ delay?: number }>(({ delay = 0 }) => ({
	"@keyframes gradient": {
		"0%": {
			transform: "translateX(-70%)",
			opacity: 0,
		},
		"5%": {
			opacity: 0.5,
		},
		"20%": {
			opacity: 1,
		},

		"80%": {
			opacity: 0.5,
		},
		"100%": {
			transform: "translateX(100%)",
			opacity: 0,
		},
	},

	animation: `gradient 3s linear ${delay}s infinite`,
}));

export const GradientWrapReverse = styled("div")<{ delay?: number }>(({ delay = 0 }) => ({
	"@keyframes gradient-reverse": {
		"0%": {
			transform: "translateX(70%)",
			opacity: 0,
		},
		"5%": {
			opacity: 0.5,
		},
		"20%": {
			opacity: 1,
		},

		"80%": {
			opacity: 0.5,
		},
		"100%": {
			transform: "translateX(-100%)",
			opacity: 0,
		},
	},

	animation: `gradient-reverse 3s linear ${delay}s infinite`,
}));
