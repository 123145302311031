import { FC, memo, useState } from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { FormWrapper, TypographyStyled, Wrapper } from "./styled";
import {
  FlexCenteredBetween,
  FlexCenteredVertically,
} from "../../styles/styled-component";
import { Typography } from "@mui/material";
import arrow from "../../assets/svg/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PagesPath } from "../../utils/route";
import { InputStyled } from "../../components/input";
import { ButtonStyled } from "../../components/form/styled";
import { DecorLine } from "../home/styled";
import { Line } from "../../assets/tsx/line";
import { useIsMobile } from "../../utils/use-is-mobile";
import { EMAIL_REGEXP, from, subject, to } from "../../components/form";
import ScrollToTop from "../../hooks/scrol-to-top";
import { useForm } from "../../hooks/use-form";
import done from "../../assets/svg/done.svg";
import LazyImage from "../../components/lazy-image";
import { HelmetTags } from "../../components/helmet";

interface IPosData {
  title: string;
  text?: string;
  do?: string;
  doText?: string;
  benefits?: string;
  benefitsText?: string;
}

const positionsData: Record<string, IPosData> = {
  "Senior-Full-Stack": {
    title: "Senior Full Stack",
    text: `
    <ul>
    <li>3+ years of development experience</li>
    <br />
    <li>Experience in leading development teams</li>
    <br />
    <li>Knowledge of TypeScript</li>
    <br />
    <li>Knowledge JavaScript frameworks (e.g. Node.js, React)</li>
    </ul>
    `,
  },
  "Senior-Backend-Developer": {
    title: "Senior Backend Developer",
    text: `
    <ul>
    <li>Work experience with Node.js, Express.js, TypeScript backend</li>
    <br />
    <li>Hands-on experience with relational databases, NoSQL databases</li>
    <br />
    <li>Experience message brokers (RabbitMQ/Kafka)</li>
    <br />
    <li>Good to know web3 lib, the Graph</li>
    <br />
    <li>Experience in blockchain projects is a plus</li>
    </ul>
    `,
  },
  "Senior-Frontend-Developer": {
    title: "Senior Frontend Developer",
    text: `
    <ul>
    <li>Work experience as a Front-end developer for more than 4 years</li>
    <br />
    <li>Hands-on experience with React, Redux, and JavaScript</li>
    <br />
    <li>Experience Typescript, Semantic UI</li>
    <br />
    <li>Strong UI / UX skills</li>
    <br />
    <li>Familiarity with browser testing and debugging</li>
    <br />
    <li>Web3 lib knowledge is a plus</li>
    <br />
    <li>Experience in blockchain projects is a plus</li>
    </ul>
    `,
  },
  "Data-Scientist": {
    title: "Data Scientist",
    text: `
    <ul>
    <li>Experience as a Data Scientist or Data Analyst more than 2 years</li>
    <br />
    <li>Knowledge of SQL and Python</li>
    <br />
    <li>Experience in data mining</li>
    <br />
    <li>Understanding of machine-learning and operations research</li>
    </ul>
    `,
  },
  "Middle-Node.js-Developer-(Backend)": {
    title: "Middle Node.js Developer (Backend)",
    text: `
    <ul>
    <li>Work experience with Node.js, Express.js, TypeScript backend</li>
    <br />
    <li>Hands-on experience with relational databases, NoSQL databases</li>
    <br />
    <li>Experience with message brokers (RabbitMQ/Kafka)</li>
    <br />
    <li>Knowledge of web3 lib and the Graph</li>
    <br />
    <li>Experience in blockchain projects is a plus</li>
    </ul>
    `,
  },
  "Manual-QA-(Middle-level)": {
    title: "Manual QA (Middle level)",
    text: `
    <ul>
    <li>Experience as a Quality Assurance Tester for more than 3 years</li>
    <br />
    <li>Experience in project management and QA methodology</li>
    <br />
    <li>Ability to document and troubleshoot errors</li>
    <br />
    <li>Excellent communication skills</li>
    <br />
    <li>Attention to detail</li>
    <br />
    <li>Analytical mind and problem-solving aptitude</li>
    </ul>
    `,
  },
};

const PositionPage: FC = memo(() => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { id } = useParams();

  const curData = positionsData[id as string];

  const [response, setResponse] = useState<{
    status?: string;
    message?: string;
  }>();

  const formInitValues: Record<string, string> = {
    email: "",
    name: "",
    message: "",
  };
  const errorsInitValues: Record<string, boolean> = {
    email: false,
    name: false,
    message: false,
  };

  const { onFieldChange, values, resetForm, errors, setErrors } = useForm({
    formInitValues,
    errorsInitValues,
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const validName = values.name.trim().length > 0;
    const validEmail = EMAIL_REGEXP.test(values.email);

    const emailData = {
      to,
      subject,
      from,
      ...values,
    };

    if (validName && validEmail) {
      try {
        const res = await fetch("https://dev.gammaprime.fi/sendEmail.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        });

        const result = await res.json();
        setResponse(result);
      } catch (error) {
        setResponse({ status: "error", message: "Failed to send email" });
      }

      resetForm();
    } else {
      if (!validName) {
        setErrors((prev) => ({ ...prev, name: true }));
      }
      if (!validEmail) {
        setErrors((prev) => ({ ...prev, email: true }));
      }
    }
  };

  return (
    <div>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      <Wrapper sx={{ marginBottom: "250px" }}>
        <FlexCenteredVertically
          gap="4px"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(PagesPath.careers)}
        >
          <LazyImage src={arrow} alt="arrow" />
          <Typography
            variant="body1"
            fontWeight={700}
            letterSpacing="-0.8px"
            textTransform="uppercase"
          >
            Go Back
          </Typography>
        </FlexCenteredVertically>

        <FlexCenteredBetween
          sx={{
            marginTop: "16px",
            gap: "130px",
            alignItems: "start",
            position: "relative",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <DecorLine sx={{ height: 496, right: "30%", top: "63%" }} delay={3}>
            <Line height={161} style={{ position: "relative", top: "60%" }} />
          </DecorLine>
          <DecorLine sx={{ height: 496, right: "5%", top: "33%" }} delay={3}>
            <Line height={161} style={{ position: "relative", top: "60%" }} />
          </DecorLine>
          <div>
            <Typography
              variant="h3"
              fontSize={isMobile ? "24px" : "48px"}
              fontWeight={500}
              lineHeight="93%"
              letterSpacing={isMobile ? "-1.68px" : "-3.36px"}
            >
              {curData.title}
            </Typography>
            {curData?.text && (
              <TypographyStyled
                variant="body1"
                fontWeight={400}
                lineHeight="150%"
                letterSpacing="-0.32px"
                sx={{ opacity: 0.8 }}
                maxWidth="757px"
                marginTop="36px"
                dangerouslySetInnerHTML={{ __html: curData.text }}
              />
            )}

            {curData?.do && (
              <Typography
                fontSize="24px"
                fontWeight={500}
                lineHeight="93%"
                letterSpacing="-1.68px"
                marginTop="72px"
                dangerouslySetInnerHTML={{ __html: curData?.do }}
              />
            )}

            {curData?.doText && (
              <Typography
                variant="body1"
                fontWeight={400}
                lineHeight="150%"
                letterSpacing="-0.32px"
                sx={{ opacity: 0.8 }}
                maxWidth="757px"
                marginTop="36px"
                dangerouslySetInnerHTML={{ __html: curData.doText }}
              />
            )}

            {curData?.benefits && (
              <Typography
                fontSize="24px"
                fontWeight={500}
                lineHeight="93%"
                letterSpacing="-1.68px"
                marginTop="72px"
                dangerouslySetInnerHTML={{ __html: curData?.benefits }}
              />
            )}

            {curData?.benefitsText && (
              <Typography
                variant="body1"
                fontWeight={400}
                lineHeight="150%"
                letterSpacing="-0.32px"
                sx={{ opacity: 0.8 }}
                maxWidth="757px"
                marginTop="36px"
                dangerouslySetInnerHTML={{ __html: curData.benefitsText }}
              />
            )}
          </div>

          <FormWrapper>
            {response?.status === "success" ? (
              <FlexCenteredVertically flexDirection="column">
                <LazyImage src={done} alt="done" />
                <Typography
                  fontSize={36}
                  fontWeight={700}
                  letterSpacing={-2.16}
                  lineHeight="86%"
                  textAlign="center"
                  marginTop="32px"
                >
                  Done!
                </Typography>

                <Typography
                  fontSize={16}
                  fontWeight={400}
                  letterSpacing={-0.48}
                  lineHeight="86%"
                  textAlign="center"
                  marginTop="12px"
                  maxWidth={182}
                >
                  We will try to answer you as soon as possible!
                </Typography>

                <ButtonStyled
                  onClick={() => setResponse({})}
                  sx={{
                    marginTop: "40px",
                  }}
                  fullWidth
                >
                  Ок
                </ButtonStyled>
              </FlexCenteredVertically>
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <InputStyled
                    type={"text"}
                    lable={"Your Name"}
                    onChangeValue={onFieldChange}
                    value={values.name}
                    error={errors.name}
                    name="name"
                  />

                  <InputStyled
                    type={"text"}
                    lable={"Email"}
                    sx={{ marginTop: "46px" }}
                    onChangeValue={onFieldChange}
                    value={values.email}
                    error={errors.email}
                    name="email"
                  />
                  <InputStyled
                    type={"text"}
                    lable={"Cover Letter"}
                    sx={{ marginTop: "46px" }}
                    onChangeValue={onFieldChange}
                    value={values.message}
                    error={errors.message}
                    name="message"
                  />
                  <ButtonStyled
                    type="submit"
                    disabled={
                      values.email.length < 1 ||
                      values.name.length < 1 ||
                      Object.values(errors).some((error) => error)
                    }
                    sx={{ marginTop: "32px" }}
                    fullWidth
                  >
                    Apply Now
                  </ButtonStyled>
                </form>
              </>
            )}
          </FormWrapper>
        </FlexCenteredBetween>
      </Wrapper>
      <Footer />
    </div>
  );
});

export default PositionPage;
