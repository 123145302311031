import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./App";
import "./styles/globals.css";
import "./styles/font/Aeroport/font.css";
import { BrowserRouter } from "react-router-dom";
import { AdaptivityProvider } from "./styles/adaptivity";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AdaptivityProvider>
          <App />
        </AdaptivityProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
