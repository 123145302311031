import { Typography } from "@mui/material";
import { FC, memo, useCallback, useState } from "react";
import { ButtonStyled, FormStyled, Wrapper } from "./styled";
import { DecorLine } from "../../feature/home/styled";
import { Line } from "../../assets/tsx/line";
import { InputStyled } from "../input";
import { Badge } from "../badge";
import { useIsMobile } from "../../utils/use-is-mobile";
import { useForm } from "../../hooks/use-form";
import done from "../../assets/svg/done.svg";
import { FlexCenteredVertically } from "../../styles/styled-component";
import LazyImage from "../lazy-image";
import { Survey } from "../survey";
import { useSurveyMutation } from "../../services/api";

interface IProps {
  title: string;
  badge?: string;
  withMessage?: boolean;
}

export const to = "info@gammaprime.fi";
export const from = "gammaprime@gmail.com";
export const subject = "Message from gammaprime.fi";

export const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/i;

export const Form: FC<IProps> = memo(({ title, badge, withMessage }) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const [subscribe] = useSurveyMutation();

  const [response, setResponse] = useState<{
    status?: string;
    message?: string;
  }>();

  const formInitValues: Record<string, string> = {
    email: "",
    name: "",
    message: "",
  };
  const errorsInitValues: Record<string, boolean> = {
    email: false,
    name: false,
    message: false,
  };

  const { onFieldChange, values, resetForm, errors, setErrors } = useForm({
    formInitValues,
    errorsInitValues,
  });

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      const validName = values.name.trim().length > 0;
      const validEmail = EMAIL_REGEXP.test(values.email);

      if (validName && validEmail) {
        try {
          subscribe({
            email: values.email,
            question: values.email,
            answer: values.email,
          })
            .unwrap()
            .then(() => {
              setOpen(true);
            });
        } catch (error) {
          setResponse({ status: "error", message: "Failed to send email" });
        }
      } else {
        if (!validName) {
          setErrors((prev) => ({ ...prev, name: true }));
        }
        if (!validEmail) {
          setErrors((prev) => ({ ...prev, email: true }));
        }
      }
    },
    [setErrors, subscribe, values.email, values.name]
  );

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  return (
    <>
      <Survey open={open} handleClose={handleClose} email={values.email} />
      <Wrapper className="wrapper" id="form">
        <DecorLine white sx={{ height: 765, left: "10%", top: "-3%" }}>
          <Line height={161} style={{ position: "relative", top: "60%" }} />
        </DecorLine>

        <DecorLine white sx={{ height: 684, right: "5%", top: "33%" }}>
          <Line height={161} style={{ position: "relative", top: "40%" }} />
        </DecorLine>
        {badge?.length ? (
          <Badge text={badge} type="secondary" size="medium" />
        ) : null}
        <Typography
          fontSize={isMobile ? "46px" : "93px"}
          fontWeight={500}
          maxWidth="1060px"
          lineHeight="93%"
          textAlign="center"
          marginTop="20px"
          dangerouslySetInnerHTML={{ __html: title }}
          className="title"
        />

        <FormStyled>
          <form onSubmit={handleSubmit}>
            <InputStyled
              type={"text"}
              lable={"Your Name"}
              onChangeValue={onFieldChange}
              value={values.name}
              name="name"
              error={errors.name}
            />

            <InputStyled
              type={"text"}
              lable={"Email"}
              sx={{ marginTop: "30px" }}
              onChangeValue={onFieldChange}
              value={values.email}
              name="email"
              error={errors.email}
            />

            {withMessage ? (
              <InputStyled
                type={"text"}
                lable={"Tell us about you"}
                sx={{ marginTop: "30px" }}
                onChangeValue={onFieldChange}
                value={values.message}
                name="message"
                error={errors.message}
              />
            ) : null}

            <ButtonStyled
              type="submit"
              fullWidth
              disabled={
                values.email.length < 1 ||
                values.name.length < 1 ||
                Object.values(errors).some((error) => error)
              }
            >
              Join Beta Now
            </ButtonStyled>
          </form>
        </FormStyled>
      </Wrapper>
    </>
  );
});

Form.displayName = "Form";
