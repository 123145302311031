import Mark from '../assets/png/mark.png'
import Dave from '../assets/png/dave.png'
import Kina from '../assets/png/kina.png'
import Andy from '../assets/png/andy.png'
import Colleen from '../assets/png/Colleen.png'
import Korath from '../assets/png/Korath.png'
import Leo from '../assets/png/Leo.png'
import Robert from '../assets/png/Robert.png'
import John from '../assets/png/John.png'
import Evan from '../assets/png/Evan.png'
import Tyler from '../assets/png/Tyler.png'
import Alessandro from '../assets/png/Alessandro.png'

export const firstTeam = [
    {
      name: "Mark",
      photo: Mark,
      text: "Chicago floor veteran: led SPX-QQQ risk arbitrage, integrated HFT algos, orchestrated large index arbitrage operations.<br/> Over 30 years of trading experience.",
    },
    {
      name: "Evan Szu",
      photo: Evan,
      text: "30 years commodity futures and options, serial entrepreneur of multiple Web3 and technology startups, machine learning engineer, Stanford PhD",
    },
    {
      name: "Dave",
      photo: Dave,
      text: "Professor of derivatives, expert at Clear Ark, DV Trading; developed analytics for $6B+ advisor, enhancing GP's capabilities.",
    },
  ];
  
  export const secondTeam = [
    {
      name: "Kina",
      photo: Kina,
      text: "Warren Miller Films and Teton Gravity Research, Redbull, Salesforce, Intel & Nike: spearheaded branding for major brands.",
    },
    {
      name: "Andy",
      photo: Andy,
      text: "Senior software engineer in financial trading, adept in C++, architecture, and team management for 22+ years.",
    },
    {
      name: "Colleen",
      photo: Colleen,
      text: "A seasoned strategist from IBM, EY, and McKenna Associates, excels in leading mission-critical operations and operational excellence.",
    },
    {
      name: "Korath",
      photo: Korath,
      text: "Diverse asset manager, finance leader, expanded global financial products.",
    },
    {
      name: "Leo",
      photo: Leo,
      text: "Former Data Scientist at Amazon, Uniswap, Ribbon & Yearn Finance",
    },
    {
      name: "Robert",
      photo: Robert,
      text: "32+ years experience, Robert is a seasoned global CTO, specializing in financial systems, trading, and software.",
    },
    {
      name: "John",
      photo: John,
      text: "26+ years, experienced in developing robust hyper performance, mission critical financial infrastructure.",
    },
    {
      name: "Tyler",
      photo: Tyler,
      text: "Quantitative Analyst at Amberdata Inc., Dipsea Capital, and Genesis Volatility; expert in Python, API integrations.",
    },
    // {
    //   name: "Alessandro",
    //   photo: Alessandro,
    //   text: "Head of Growth at Transak, co-Founder of Web3Mi & EthMilan",
    // },
  ];