import { Typography, styled } from "@mui/material";
import { FlexCenteredBetween, FlexCenteredVertically } from "../../styles/styled-component";
import { forMobile } from "../../styles/adaptivity";

export const Image = styled('img')(() => ({
    marginTop: 53,
    [forMobile]: {
        marginTop: 48,
    }
}))

export const PositionsWrapper = styled(FlexCenteredVertically)(() => ({
    marginTop: 163,
    flexDirection: 'column',
    width: '100%',
    position: 'relative',

    [forMobile]: {
        marginTop: 137,
    }
}))

export const Positions = styled('div')(() => ({
    display: 'grid',
    gap: 20,
    marginTop: 24,
    width: '100%',

    [forMobile]: {
        marginTop: 16,
    }
}))

export const TextWrapper = styled('div')(() => ({
    position: 'relative',
    maxWidth: 1312,
    width: '100%',
    marginTop: 194,

    [forMobile]: {
        marginTop: 159,
    }
}))

export const Text = styled(Typography)(() => ({
    '& span': {
        color: '#464758',
    },
}))

export const Position = styled(FlexCenteredBetween)(() => ({
    borderRadius: 16,
    border: '1px solid #373737',
    background: '#08091D',
    zIndex: 1,
    padding: '22px 32px 20px 24px',
    maxWidth: 868,
    width: '100%',
    margin: '0 auto',
    cursor: 'pointer',

    [forMobile]: {
        padding: 20,
        flexDirection: 'column',
        alignItems: 'start',
        gap: 29,
    },

    '& .text': {
        [forMobile]: {
            order: 3
        }
    },
    '& .first-badge': {
        [forMobile]: {
            order: 1
        }
    },
    '& .second-badge': {
        [forMobile]: {
            order: 2
        }
    },
}))