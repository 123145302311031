import { FC, memo } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Typography, styled } from "@mui/material";
import { forMobile } from "../styles/adaptivity";
import ScrollToTop from "../hooks/scrol-to-top";
import { HelmetTags } from "../components/helmet";

const Container = styled("div")(() => ({
  maxWidth: 800,
  margin: "0 auto",

  "& a": {
    color: "#FFF",
  },

  [forMobile]: {
    padding: "0 16px",
  },
}));

const TermsofService: FC = memo(() => {
  return (
    <div>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      <Container>
        <Typography
          variant="h3"
          fontSize={"48px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-3.36px"
          marginTop="100px"
        >
          Terms of Service
        </Typography>

        <Typography
          fontSize={24}
          fontWeight={300}
          lineHeight="93%"
          letterSpacing={-1.68}
          marginTop="16px"
        >
          Effective date: June 4th, 2024
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
          color="#43D090"
        >
          By using or accessing the Services in any manner, you acknowledge that
          you accept the practices and policies outlined in this Terms of
          Service, and you hereby consent that we will collect, use, and share
          your information in the following ways.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          These Terms of Service (these “Terms”) outline the terms and
          conditions by which you may access and use our website, gammaprime.fi
          (the “Website”), operated by or on behalf of Gamma Prime (inclusive
          with its affiliates, the “Company”, “we”, or “us”), our App (as
          defined below), and any other services provided by the Company,
          including any related content, tools, documentation, features, and
          functionality collectively the “Services”. These Terms govern your
          access to and use of the Services. Please read these Terms carefully,
          as they include important information about your legal rights. By
          accessing and/or using the Services, you are agreeing to these Terms.
          If you do not understand or agree to these Terms, please do not use
          the Services. For the purposes of these Terms, “you” and “your” refer
          to you as the user of the Services. If you use the Services on behalf
          of a company or other entity, then “you” includes both you and that
          entity, and you represent and warrant that (a) you are an authorized
          representative of the entity with the authority to bind the entity to
          these Terms, and (b) you agree to these Terms on the entity's behalf.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          1. The Services
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          1.1 Services. The Services provide an interface (the “App”) that
          displays data for the purpose of enabling users to interact, via a
          third-party wallet application (e.g., Metamask), with certain
          components of a set of open-source smart contracts deployed on
          decentralized blockchains, specifically the “staking” mechanism
          inherent to the Gamma Prime. This set of smart contracts, collectively
          with off-chain infrastructure maintained and operated by the Gamma
          Prime Foundation and its affiliates, is referred to herein as the
          “Protocol”. Additionally, the App displays data to enable users to
          interact, via a third-party wallet application, with components of
          other open-source smart contract systems deployed on decentralized
          blockchains, such as certain liquidity pools on Curve Finance and
          Uniswap (the “Third-Party Protocols”). Documentation relevant to the
          Services, the App, and the Protocol is available at gammaprime.fi (the
          “Documentation”). The Protocol itself is not part of the Services, and
          your use of the Protocol is entirely at your own risk. Furthermore,
          the third-party technologies required to be used or interacted with to
          interface with the Protocol, including but not limited to a Wallet (as
          defined below, and collectively the “Third-Party Tools”), are not part
          of the Services, and your use of such Third-Party Tools is entirely at
          your own risk. The App is separate and distinct from the Protocol and
          any Third-Party Tools and is not essential for accessing or otherwise
          interacting with the Protocol. The App merely displays blockchain data
          and provides a web application that simplifies the use of Third-Party
          Tools to interact with the Protocol. Activities on the Protocol are
          conducted via permissionless smart contracts as well as certain
          off-chain infrastructure detailed in the Documentation and maintained
          by the Gamma Prime Foundation and its affiliates, and users or other
          developers are free to create their own interfaces to interact with
          the Protocol. When you utilize any data inputs provided by the App to
          execute transactions, you are interacting with public blockchains,
          which provide transparency into your transactions. The Company does
          not control and is not responsible for any information you make public
          on any public blockchain by taking actions utilizing data provided by
          the App or the Services. 1.2 Wallets. To use certain Services, it may
          be necessary to connect a third-party digital wallet (“Wallet”) to the
          App. By using a Wallet in connection with the Services, you agree that
          you are using the Wallet under the terms and conditions of the
          applicable third-party provider of such Wallet. Wallets are not
          associated with, maintained by, supported by, or affiliated with the
          Company. You acknowledge and agree that we are not a party to any
          transactions concluded while or after accessing our App, and we do not
          have possession, custody, or control over any digital assets appearing
          on the App. When you interact with the App, you retain control over
          your digital assets at all times. The Company accepts no
          responsibility or liability to you in connection with your use of a
          Wallet or data provided by the App in consummating transactions using
          a Wallet, and makes no representations and warranties regarding how
          the Services will interact with any specific Wallet. The private keys
          and/or seed phrases necessary to access the assets held in a Wallet
          are not held by or known to the Company. The Company has no ability to
          help you access or recover your private keys and/or seed phrases for
          your Wallet, so please keep them in a safe place. 1.3 Updates;
          Monitoring. We may make any improvements, modifications, or updates to
          the Services, including but not limited to changes and updates to the
          underlying software, infrastructure, security protocols,
          documentation, technical configurations, or service features (the
          “Updates”) from time to time. Your continued access to and use of the
          Services are subject to such Updates and you shall accept any patches,
          system upgrades, bug fixes, feature modifications, or other
          maintenance work that arise out of such Updates. We are not liable for
          any failure by you to accept and use such Updates in the manner
          specified or required by us. Although the Company is not obligated to
          monitor access to or participation in the Services, it has the right
          to do so for the purpose of operating the Services, ensuring
          compliance with the Terms, and complying with applicable law or other
          legal requirements. 1.4 Fees. While the Company does not presently
          charge any fees for the Services or the App, transactions executed by
          you utilizing data provided by the App and your use of the Services
          may cause you to incur fees such as blockchain gas or similar network
          fees, as well as fees charged by the Protocol, if any, and Third-Party
          Protocols. All such fees displayed within your Wallet utilizing data
          inputs provided by the App are merely estimates and may not reflect
          actual costs incurred in broadcasting a transaction for execution
          according to the applicable consensus mechanism. Additionally, your
          external Wallet provider may impose a fee. We are not responsible for
          any fees charged by a third party. Due to the nature of distributed,
          public blockchains, transactions executed by you utilizing data
          provided by the App and the Services are non-refundable, and the
          Company is not able to alter or mitigate any such fees. You will be
          responsible for paying any and all taxes, duties, and assessments now
          or hereafter claimed or imposed by any governmental authority
          associated with your use of the Services, the App, the Protocol, and
          Third-Party Protocols. In certain cases, your transactions may not be
          successful due to an error with the blockchain or the Wallet, or due
          to changes in the distributed blockchain environment (e.g., during a
          spike in demand for block space and/or activity on the relevant
          network). We accept no responsibility or liability to you for any such
          failed transactions, or any transaction or gas fees that may be
          incurred by you in connection with such failed transactions.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          2. Who May Use the Services.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          You must be 18 years of age or older and not be a Prohibited Person to
          use the Services. A “Prohibited Person” is any person or entity that
          is (a) listed on any U.S. Government list of prohibited or restricted
          parties, including the U.S. Treasury Department's list of Specially
          Designated Nationals or the U.S. Department of Commerce Denied Persons
          List or Entity List, the EU consolidated list of persons, groups and
          entities subject to financial sanctions, the UK Consolidated List of
          Financial Sanctions Targets, (b) located or organized in any U.S.
          embargoed countries or any country that has been designated by the
          U.S. Government as “terrorist supporting”, (c) a citizen, resident, or
          organized in, the following jurisdictions (the “Prohibited
          Jurisdictions”): Abkhazia, Afghanistan, Angola, Belarus, Burundi,
          Central African Republic, Congo, Cuba, Crimea, Ethiopia,
          Guinea-Bissau, Iran, Ivory Coast (Cote D’Ivoire), Lebanon, Liberia,
          Libya, Mali, Burma (Myanmar), Nicaragua, North Korea, Northern Cyprus,
          Russia, Somalia, Somaliland, South Ossetia, South Sudan, Sudan, Syria,
          Ukraine (Donetsk and Luhansk regions), United States, Venezuela,
          Yemen, Zimbabwe; (d) otherwise a party with which the Company is
          prohibited to deal under the laws of the U.S., the EU (or any of its
          Member States), the UK, or any applicable foreign jurisdiction, or (e)
          owned or controlled by such persons or entities listed in (a)-(d). The
          Company may utilize certain tools, such as IP-based geofencing, to
          enforce the above restrictions. By using the Services and/or the App,
          you represent that you are not a Prohibited Person. You acknowledge
          and agree that you are solely responsible for complying with all
          applicable laws of the jurisdiction you are located or accessing the
          Services from in connection with your use of the Services. By using
          the Services, you represent and warrant that you meet these
          requirements and will not be using the Services for any illegal
          activity or to engage in the prohibited activities in Section 5.3. We
          may require you to provide additional information and documents
          regarding your use of the Services, including in case of application
          of any applicable law or regulation, including laws related to
          anti-money laundering or for countering the financing of terrorism, or
          the request of any competent authority. We may also require you to
          provide additional information or documents in cases where we have
          reason to believe: (i) that your Wallet is being used for illegal
          money laundering or for any other illegal activity; or (ii) you have
          concealed or reported false identification information or other
          details.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          3. Location of Our Privacy Policy.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          Our Privacy Policy describes how we handle the information you provide
          to us when you use the Services.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          4. Rights We Grant You.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          4.1 Right to Use Services. We allow you to use the Services for
          personal, non-commercial use, provided you comply with these Terms. If
          we distribute software, content, or other materials as part of your
          use of the Services, you get a personal, non-assignable,
          non-sublicensable, non-transferable, and non-exclusive right and
          license to access and display them solely to enable your use of the
          Services as permitted by these Terms. Your access may be interrupted
          occasionally for various reasons, including equipment malfunctions,
          updates, maintenance, or other actions we may choose to take. 4.2
          Restrictions on Your Use of the Services. Unless prohibited by law or
          you have our written permission, you may not: (a) download, modify,
          copy, distribute, transmit, display, perform, reproduce, publish,
          license, create derivative works from, or sell any information from
          the Services, except for temporary files automatically cached by your
          browser; (b) duplicate, decompile, reverse engineer, disassemble, or
          decode the Services; (c) use, reproduce, or remove any copyright,
          trademark, or other proprietary notices on the Services; (d) use
          unauthorized software to modify the Services; (e) exploit the Services
          for commercial purposes; (f) access the Services in a way that could
          disable or impair them; (g) attempt unauthorized access to the
          Services or related systems; (h) circumvent or tamper with security
          measures; (i) use automated tools to scrape or collect data from the
          Services; (j) introduce malicious software into our systems; (k) post
          content that is unlawful, defamatory, obscene, violent, or otherwise
          objectionable; (l) violate any laws while using the Services; or (m)
          use the Services in any manner not expressly permitted by these Terms.
          4.3 Interactions with Other Users on the Services. You are responsible
          for your interactions with other users on the Services. We may monitor
          user interactions but are not obligated to do so and are not liable
          for your interactions or any user's actions. If you have a dispute
          with another user, you release us and our affiliates from all claims
          and damages arising from such disputes. By entering this release, you
          waive any protections that limit the scope of this release to claims
          you know or suspect to exist at the time of the release.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          5. Ownership and Content.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          5.1 Ownership of the Services. The Services, including their "look and
          feel" (e.g., text, graphics, images, logos), proprietary content,
          information, and other materials, are protected by intellectual
          property laws. You agree that the Company and its licensors own all
          rights to the Services and their content, and you will not take any
          actions inconsistent with these ownership rights. We and our licensors
          reserve all rights, including the exclusive right to create derivative
          works. 5.2 Ownership of Trademarks. The Company’s name, trademarks,
          logos, and all related names, logos, product and service names,
          designs, and slogans are trademarks of the Company or its affiliates
          or licensors. Any other names, logos, product and service names,
          designs, and slogans appearing on the Services belong to their
          respective owners, who may not be affiliated with, connected to, or
          sponsored by us. 5.3 Ownership of Feedback. We welcome feedback, bug
          reports, comments, and suggestions for improvements to the Services
          (“Feedback”). You acknowledge and expressly agree that providing
          Feedback does not grant you any right, title, or interest in the
          Services or the Feedback itself. All Feedback becomes the exclusive
          property of the Company, and the Company may use and disclose such
          Feedback in any manner and for any purpose without further notice or
          compensation to you, retaining no proprietary or other rights or
          claims. You hereby assign to the Company any and all rights, titles,
          and interests (including, but not limited to, any patents, copyrights,
          trade secrets, trademarks, know-how, show-how, moral rights, and all
          other intellectual property rights) that you may have in and to any
          and all Feedback.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          6. Third Party Services and Materials.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          The Services, through the App, may provide data relevant to
          Third-Party Protocols. The Services may display, include, or make
          available content, data, information, applications, or materials from
          third parties (“Third-Party Materials”) or provide links to certain
          third-party websites. The Company does not endorse any Third-Party
          Materials or any provider of Third-Party Protocols. Your access and
          use of such Third-Party Protocols and Third-Party Materials are
          governed solely by their respective terms and conditions. The Company
          is not responsible or liable for, and makes no representations about,
          any aspect of such Third-Party Materials and Third-Party Protocols,
          including their content, operation, or data handling, protection,
          management, or processing. The Company does not examine or evaluate
          the content, accuracy, completeness, availability, timeliness,
          validity, copyright compliance, legality, decency, quality, risk,
          functionality, or safety of such Third-Party Protocols or Third-Party
          Materials or websites. You irrevocably waive any claims against the
          Company regarding these Third-Party Protocols and Third-Party
          Materials. We are not liable for any damage or loss caused or alleged
          to be caused by or in connection with your enablement, access, or use
          of any Third-Party Protocols or Third-Party Materials, or your
          reliance on their privacy practices, data security processes, or other
          policies. Third-Party Protocols, Third-Party Materials, and links to
          other websites are provided solely for your convenience.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          7. Disclaimers, Limitations of Liability and Indemnification.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          7.1 Disclaimers. Your access to and use of the Services and the
          Protocol are at your own risk. The Services are provided on an “AS IS”
          and “AS AVAILABLE” basis. To the fullest extent permitted by
          applicable law, the Company, its affiliates, related companies,
          officers, directors, employees, agents, representatives, partners, and
          licensors (“Company Entities”), and Multisig Members (as defined
          below) DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED, OR
          STATUTORY, INCLUDING WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, NON-INFRINGEMENT, USAGE, QUALITY, PERFORMANCE,
          SUITABILITY, OR FITNESS OF THE SERVICES AND THE PROTOCOL FOR ANY
          PURPOSE, AS WELL AS ACCURACY, QUALITY, SEQUENCE, RELIABILITY,
          WORKMANSHIP, OR TECHNICAL CODING THEREOF, OR THE ABSENCE OF DEFECTS,
          WHETHER LATENT OR PATENT. The Company Entities and Multisig Members
          make no warranties or representations and disclaim all responsibility
          and liability for: (a) the completeness, accuracy, availability,
          timeliness, security, or reliability of the Services and the Protocol;
          (b) any harm to your computer system, loss of data, or other harm
          resulting from your access to or use of the Services or the Protocol;
          (c) operation or compatibility with any other application or system,
          including Wallets; and (d) whether the Services or the Protocol will
          meet your requirements or be available uninterrupted, secure, or
          error-free. The Company is registered as a VASP with the Italian
          Organismo Agente e Mediatori. Beyond this registration, the Company is
          not registered with any other regulatory body in any jurisdiction. You
          understand and acknowledge that we do not broker trading orders, match
          orders for securities or other assets, or offer any products for sale
          or distribution. We also do not facilitate the execution or settlement
          of transactions, which occur entirely on public distributed
          blockchains. The App is strictly a tool for users to construct
          transaction data to be utilized by executing transactions with
          Wallets. No advice or information, oral or written, obtained from the
          Company Entities or through the Services, will create any warranty or
          representation not expressly made herein. You agree that all
          transfers, staking, or other actions performed using transaction data
          from the App are considered unsolicited, meaning you have not received
          investment advice from us, we have not actively solicited your use of
          the Services, and we do not conduct a suitability review of any
          action. All information provided by the App and Services is for
          informational purposes only and should not be construed as investment
          advice. You are solely responsible for determining the appropriateness
          of any investment, investment strategy, or related transaction based
          on your personal objectives, financial circumstances, and risk
          tolerance. 7.2 Limitations of Liability. TO THE EXTENT NOT PROHIBITED
          BY LAW, THE COMPANY ENTITIES OR MULTISIG MEMBERS SHALL NOT BE LIABLE
          FOR ANY DAMAGES, INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY,
          INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING PROCUREMENT
          OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA, OR PROFITS,
          BUSINESS INTERRUPTION, OR OTHER DAMAGES OR LOSSES) ARISING OUT OF OR
          RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES, UNDER ANY THEORY
          OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE, AND WHETHER IN
          CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
          OTHERWISE) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
          COMPANY ENTITIES' TOTAL LIABILITY FOR ANY DAMAGES SHALL NOT EXCEED ONE
          HUNDRED DOLLARS ($100.00). SOME JURISDICTIONS DO NOT ALLOW EXCLUSION
          OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU. 7.3 Assumption of Risks. (a) By using
          the Services, you represent that you have sufficient knowledge and
          experience in business and financial matters, including blockchain
          technologies, cryptocurrencies, and other digital assets, to assess
          and evaluate the risks and benefits of the Services. You acknowledge
          the risks associated with purchasing and holding cryptocurrency, using
          blockchain technology, and staking cryptocurrency, including the risk
          of losing access to cryptocurrency, mining or blockchain attacks,
          hacking and security weaknesses, unfavorable regulatory intervention,
          token taxation, personal information disclosure, uninsured losses,
          volatility, and unanticipated risks. Cryptocurrencies and other
          digital assets are not deposits guaranteed by a bank or insured by the
          FDIC or any governmental agency. (b) Multi-signature crypto wallets
          (“MultiSigs”) and their signatories (“MultiSig Members”) may have
          certain controls related to the Protocol. These controls might include
          pausing functionality, implementing upgrades, and other functions.
          While some MultiSig Members may be affiliated with the Company, others
          may not be. We cannot control the actions of unaffiliated MultiSig
          Members and thus cannot be held liable for their actions or inactions.
          (c) Regulatory regimes governing blockchain technologies,
          cryptocurrencies, and other digital assets are uncertain, and new
          regulations may adversely affect the utility or value of the Services,
          the Protocol, Third-Party Protocols, cryptocurrencies, and other
          digital assets. Taxation of cryptocurrency activities and transactions
          is also uncertain. You should consult your tax advisor regarding the
          potential tax implications of using the Services, the App, and the
          Protocol. (d) Market sentiment, liquidity, and third-party services or
          platforms that support, quote, restrict, or provide access to
          cryptocurrencies and other digital assets are beyond our control. We
          expressly deny and disclaim any liability for losses resulting from
          fluctuations in cryptocurrency value. (e) Smart contracts execute
          automatically when conditions are met. Transactions on blockchains or
          using smart contracts often cannot be stopped or reversed, and
          vulnerabilities in programming, design, or implementation may result
          in significant adverse effects, including loss of digital assets. (f)
          The Documentation describes certain risks associated with the Protocol
          in detail. Please review the Documentation for additional risks. The
          Company disclaims liability for risks disclosed in the Documentation
          to the fullest extent permitted by law. 7.4 Indemnification. By
          entering these Terms and using the Services, you agree to defend,
          indemnify, and hold harmless the Company Entities and MultiSig Members
          from any claims, costs, damages, losses, liabilities, and expenses
          (including attorneys' fees and costs) arising from: (a) your violation
          of these Terms or any law or regulation; (b) your violation of any
          third-party rights; (c) your misuse of the Services; or (d) your
          negligence or willful misconduct. If you are obligated to indemnify
          any Company Entity, the Company may control the defense or settlement
          of such claim and you agree to cooperate fully. 7.5 Third Party
          Beneficiaries. You and the Company acknowledge that the Company
          Entities (excluding the Company) and MultiSig Members are third-party
          beneficiaries of these Terms, including Sections 7 and 8.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          8. ARBITRATION AND CLASS ACTION WAIVER
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          8.1 PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
          YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND
          TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY
          BINDING ARBITRATION AND A CLASS ACTION WAIVER. 8.2 Informal Process
          First. You and the Company agree that before resorting to formal
          dispute resolution methods, including court action, both parties will
          first contact each other and make a good faith effort to resolve any
          dispute within 30 days. This informal process is a prerequisite to
          initiating any arbitration against the Company, any Company Entity, or
          any MultiSig Members. 8.3 Arbitration Agreement and Class Action
          Waiver. If the dispute remains unresolved after the informal process,
          it will be settled by arbitration under the Rules of the Milan Chamber
          of Arbitration, by three arbitrators, in English. This arbitration
          will be on an individual basis – class arbitrations and class actions
          are not permitted. By agreeing to these Terms, both you and the
          Company waive the right to a jury trial or to participate in a class
          action or class arbitration. 8.4 Exceptions. Certain disputes will be
          resolved in court: (i) those within small claims court jurisdiction;
          (ii) those where the sole relief sought is injunctive relief; or (iii)
          intellectual property disputes. 8.5 Costs of Arbitration. The payment
          of arbitration costs will follow the Rules, but if such costs are
          prohibitively expensive compared to court proceedings, the Company
          will cover the necessary costs. If your claim is deemed frivolous, you
          will reimburse the Company for all previously paid costs that are your
          obligation under the rules. 8.6 Opt-Out. You can opt out of the
          arbitration provisions by sending written notice to info@gammaprime.fi
          within 30 days of first accessing the Services or agreeing to these
          Terms. Opting out of arbitration means opting out of both arbitration
          and the class action waiver. 8.7 WAIVER OF RIGHT TO BRING CLASS ACTION
          AND REPRESENTATIVE CLAIMS. YOU AND THE COMPANY AGREE THAT ANY DISPUTE
          WILL BE RESOLVED INDIVIDUALLY AND NOT AS PART OF A CLASS ACTION. YOU
          WAIVE THE RIGHT TO PARTICIPATE IN ANY CLASS ACTION. THE ARBITRATOR
          CANNOT COMBINE CLAIMS OR PRESIDE OVER A CLASS ACTION. IF THIS WAIVER
          IS FOUND INVALID, THE ARBITRATION AGREEMENT WILL BE NULL AND VOID FOR
          SUCH PROCEEDINGS, AND THE DISPUTE MUST BE BROUGHT IN COURT.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          9. Additional Provisions.
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          9.1 Updating These Terms. We may revise these Terms periodically, and
          we will update the "Last Revised" date at the top of these Terms
          accordingly. For significant changes, we will make reasonable efforts
          to notify you, such as through email or a prominent notice on the
          Website's homepage. However, it is your responsibility to review these
          Terms periodically for any updates. The revised Terms will be
          effective upon posting or on a later specified date. Your continued
          use of the Services after the modifications take effect will signify
          your acceptance of the updated Terms. No amendments will apply to
          disputes where arbitration has already been initiated prior to the
          changes. 9.2 Suspension; Termination. If you violate any provision of
          these Terms, all licenses granted by the Company will terminate
          automatically. Furthermore, the Company may, at its discretion,
          suspend or terminate your access to or use of any Services, with or
          without notice, for any reason, including but not limited to: (i) if
          we believe you have engaged in prohibited activities as outlined in
          Section 4.2; (ii) if you provide incomplete, incorrect, or false
          information; (iii) if you breach any part of these Terms; (iv) if you
          are a Prohibited Person or reside in a Prohibited Jurisdiction; and/or
          (v) if it is necessary to comply with these Terms, our policies, or
          any applicable law or regulation. Sections that naturally survive
          termination will remain in full effect despite termination by either
          the Company or you. Termination does not limit the Company's other
          rights or remedies at law or in equity. 9.3 Injunctive Relief. You
          agree that any breach of these Terms may cause irreparable harm to the
          Company, for which monetary damages would not be adequate, and the
          Company shall be entitled to equitable relief in addition to any other
          remedies it may have, without needing a bond, other security, or proof
          of damages. 9.4 Force Majeure. We are not liable or responsible for
          any failure or delay in performing our obligations under these Terms
          or providing the Services due to events beyond our control, including
          acts of God, floods, fires, earthquakes, epidemics, pandemics,
          tsunamis, explosions, wars, invasions, hostilities, terrorist acts,
          riots, civil unrest, government actions, embargoes, blockades,
          strikes, labor disturbances, internet connectivity issues,
          telecommunication breakdowns, or power shortages. 9.5 Miscellaneous.
          If any provision of these Terms is found to be unlawful, void, or
          unenforceable, that provision will be considered severable and will
          not affect the validity and enforceability of the remaining
          provisions. The Company may assign these Terms and the licenses
          granted, but you may not assign them without the Company's prior
          written consent. No waiver of any breach or default will be considered
          a waiver of any preceding or subsequent breach or default. Section
          headings are for reference only and have no legal effect. The Services
          are operated by us within the European Union. Users accessing the
          Services from outside the European Union do so at their own risk and
          are responsible for compliance with local laws. These Terms are
          governed by the laws of Italy, without regard to conflict of laws
          rules, and the proper venue for any disputes will be the courts in
          Italy. 9.6 How to Contact Us. You may reach us regarding the Services
          or these Terms via email at{" "}
          <a href="malito:info@gammaprime.fi">info@gammaprime.fi</a>.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
});

export default TermsofService;
