import React, { FC, memo } from "react";
import { useIsMobile } from "../../utils/use-is-mobile";

export const Circle: FC<{ fill?: string }> = memo(({ fill = "white" }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? "8" : "18"}
      height={isMobile ? "8" : "18"}
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9" cy="9" r="9" fill={fill} />
    </svg>
  );
});
