import { Typography, styled } from "@mui/material";
import { FC, memo } from "react";

interface IProps {
  text?: string;
  type: "primary" | "secondary";
  size: "small" | "medium";
  className?: string;
}

const BadgeStyle = styled("div")<IProps>(({ size, type }) => ({
  borderRadius: "200px",
  maxWidth: "fit-content",
  border:
    type === "primary"
      ? "1px solid #39CA88"
      : "1px solid rgba(189, 137, 255, 0.20)",
  background:
    type === "primary"
      ? "rgba(57, 202, 136, 0.20)"
      : "rgba(189, 137, 255, 0.10)",
  padding: size === "medium" ? 10 : "6px 10px",
}));

export const Badge: FC<IProps> = memo(({ text, type, size, className }) => {
  return (
    <BadgeStyle type={type} size={size} className={className}>
      <Typography
        fontSize={size === "medium" ? "12px" : "14px"}
        fontWeight={size === "medium" ? 700 : 500}
        lineHeight={size === "medium" ? "124%" : "normal"}
        letterSpacing={size === "medium" ? "-0.36px" : "-0.7px"}
        color={type === "primary" ? "#39CA88" : "#D384F8"}
        whiteSpace="nowrap"
      >
        {text}
      </Typography>
    </BadgeStyle>
  );
});
