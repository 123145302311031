import React, { FC, memo } from "react";
import { Content, Wrapper } from "./styled";
import { Header } from "../../components/header";
import { Typography } from "@mui/material";
import { ButtonStyled, LineWrap } from "../home/styled";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../utils/route";
import { Line } from "../../assets/tsx/line";
import { useIsMobile } from "../../utils/use-is-mobile";
import ScrollToTop from "../../hooks/scrol-to-top";

export const NotFound: FC = memo(() => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <ScrollToTop />
      <Header />
      <Content>
        <LineWrap sx={{ left: "10%", top: "10%" }} delay={0.1}>
          <Line height={161} />
        </LineWrap>
        <LineWrap sx={{ left: "30%", top: "50%" }} delay={0.8}>
          <Line height={161} />
        </LineWrap>
        <LineWrap sx={{ right: "30%", top: "30%" }} delay={1}>
          <Line height={161} />
        </LineWrap>
        <LineWrap sx={{ right: "10%", top: "5%" }} delay={3}>
          <Line height={120} />
        </LineWrap>
        <Typography
          fontSize={isMobile ? 131 : 200}
          letterSpacing={isMobile ? "6.55px" : "-10px"}
          textAlign="center"
          fontWeight={500}
          lineHeight="93%"
        >
          404
        </Typography>

        <Typography
          variant="h3"
          fontSize={isMobile ? 29 : 48}
          marginTop={isMobile ? "28px" : "40px"}
          letterSpacing={isMobile ? "-1.45px" : "-2.4px"}
          textAlign="center"
          fontWeight={500}
        >
          Ooops! Page Not Found
        </Typography>

        <Typography
          variant="body1"
          marginTop="20px"
          color="#A8A9B2"
          textAlign="center"
          maxWidth="330px"
        >
          This page doesn’t exist or was removed! We suggest you back to home
        </Typography>

        <ButtonStyled
          onClick={() => navigate(PagesPath.home)}
          variant="contained"
          width={210}
          sx={{ marginTop: "28px" }}
        >
          Go to Home Page
        </ButtonStyled>
      </Content>
    </Wrapper>
  );
});
