import { FC, memo } from "react";
import {
  FlexCenteredBetween,
  FlexCenteredVertically,
} from "../../styles/styled-component";
import { Typography } from "@mui/material";
import { Image, LinkStyled } from "./styled";
import arrowUp from "../../assets/svg/arrow-up-right-white.svg";
import moment from "moment";
import { PagesPath } from "../../utils/route";
import { useIsMobile } from "../../utils/use-is-mobile";

interface IProps {
  image: string;
  date: string;
  title: string;
  desc: string;
  link: string;
}

export const ArticlePreview: FC<IProps> = memo(
  ({ image, date, title, desc, link }) => {
    const isMobile = useIsMobile();
    return (
      <LinkStyled
        to={`${PagesPath.blog}/${link}`}
        sx={{ width: isMobile ? "100%" : "31.7%" }}
      >
        <FlexCenteredVertically
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          sx={{ cursor: "pointer" }}
        >
          <Image src={image} alt="preview" />

          <Typography
            fontSize={14}
            fontWeight={600}
            color="#39CA88"
            marginTop="32px"
          >
            {moment(date).format("dddd, D MMM YYYY")}
          </Typography>
          <FlexCenteredBetween sx={{ marginTop: "12px", width: "100%" }}>
            <Typography
              fontSize={24}
              fontWeight={600}
              maxWidth={344}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {title}
            </Typography>

            <img src={arrowUp} alt="arrowUp" />
          </FlexCenteredBetween>

          <Typography
            fontSize={16}
            fontWeight={400}
            color="#C0C5D0"
            marginTop="12px"
            maxWidth={384}
          >
            {desc}
          </Typography>
        </FlexCenteredVertically>
      </LinkStyled>
    );
  }
);
