import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mainApi = createApi({
	reducerPath: "mainApi",
	baseQuery: fetchBaseQuery({ baseUrl: "https://gammasurv.xyz/v1" }),
	endpoints: (builder) => ({
		survey: builder.mutation({
			query: (body) => ({
				url: "/auth/survey",
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
	}),
});

export const { useSurveyMutation } = mainApi;
