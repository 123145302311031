import { Input, SxProps, Theme, Typography, styled } from "@mui/material";
import React, { FC, HTMLInputTypeAttribute, memo } from "react";

interface IProps {
  type: HTMLInputTypeAttribute | undefined;
  lable: string;
  onChangeValue: (e: React.ChangeEvent<any>) => void;
  sx?: SxProps<Theme>;
  value?: string;
  name?: string;
  error?: boolean;
}

const StyledInput = styled("input")({
  border: "none",
  minWidth: 0,
  outline: 0,
  padding: "16px 0",
  flex: 1,
  color: "#FFF",
  backgroundColor: "transparent",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontStyle: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  textOverflow: "ellipsis",
  "&::placeholder": {
    opacity: 0,
    transition: "0.1s ease-out",
  },
  "&:focus::placeholder": {
    opacity: 1,
  },
  "&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label":
    {
      top: "0px",
      fontSize: "0.75rem",
    },
  "&:focus ~ label": {
    color: "#FFF",
  },
  "&:-webkit-autofill": {
    alignSelf: "stretch", // to fill the height of the root slot
  },
  "&:-webkit-autofill:not(* + &)": {
    marginInlineStart: "calc(-1 * var(--Input-paddingInline))",
    paddingInlineStart: "var(--Input-paddingInline)",
    borderTopLeftRadius:
      "calc(var(--Input-radius) - var(--variant-borderWidth, 0px))",
    borderBottomLeftRadius:
      "calc(var(--Input-radius) - var(--variant-borderWidth, 0px))",
  },

  "&.MuiInputBase-root::before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },

  "&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },

  "&.MuiInputBase-root::after": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },
});

const CustomInput = styled(Input)({
  "&.MuiInputBase-root::before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },

  "&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },

  "&.MuiInputBase-root::after": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },

  "&.Mui-error": {},
});

const StyledLabel = styled("label")(() => ({
  position: "absolute",
  lineHeight: 1,
  top: "calc((var(--Input-minHeight) - 1em) / 2)",
  color: "#FFF",
  fontWeight: "500",
  transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
}));

const InnerInput = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements["input"]
>(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>{props.vocab}</StyledLabel>
    </React.Fragment>
  );
});

export const InputStyled: FC<IProps> = memo(
  ({ type, lable, onChangeValue, sx, value, name, error }) => {
    return (
      <span style={{ position: "relative", width: "100%" }}>
        <CustomInput
          error={error}
          onChange={(e) => onChangeValue(e)}
          value={value}
          slots={{ input: InnerInput }}
          slotProps={{
            input: { type: type, name: name, placeholder: "", vocab: lable },
          }}
          sx={{
            "--Input-minHeight": "56px",
            "--Input-radius": "6px",
            width: "100%",
            ...sx,
          }}
        />
        {error && (
          <Typography
            fontSize={12}
            fontWeight={400}
            letterSpacing={-0.12}
            lineHeight={"109%"}
            color="#FF6C67"
            sx={{ position: "absolute", left: 0, bottom: "-33px" }}
          >
            Invalid field
          </Typography>
        )}
      </span>
    );
  }
);
