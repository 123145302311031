import { FlexCenteredVertically } from "../../styles/styled-component";
import { Button, styled } from "@mui/material";
import bg from '../../assets/svg/bg-group.svg'
import { forMobile } from "../../styles/adaptivity";

export const Wrapper = styled(FlexCenteredVertically)(() => ({
    position: 'relative',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: 276,
    padding: "40px 0 134px",
    width: '100%',

    [forMobile]: {
        marginTop: 80,
        padding: "0 8px 96px",
    }

})) 

export const FormStyled = styled('div')(() => ({
    borderRadius: 12,
    background: 'linear-gradient(180deg, rgba(8, 9, 29, 0.44) 0%, rgba(36, 41, 131, 0.00) 100%)',
    backdropFilter: 'blur(8.699999809265137px)',
    padding: '40px 40px 36px',
    maxWidth: 410,
    width: '100%',
    marginTop: 40,

    [forMobile]: {
        maxWidth: '100%', 
        padding: '40px 16px 36px',
    }
})) 

export const ButtonStyled = styled(Button)(() => ({
    borderRadius: 12,
    background: '#39CA88',
    padding: 19,
    color: '#FFF',
    boxShadow: '0px 7px 32px 0px rgba(57, 202, 136, 0.30), 0px 4px 9.1px 1px #64F2B1 inset',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '-0.48px',
    marginTop: 37,
    '&:hover': {
        background: '#39CA88',
    },

    '&.Mui-disabled': {
        background: '#262626',
        color: '#FFF',
        boxShadow: 'none',
    }
})) 


