import { HomePage } from "../feature/home";
import { DefaultWrapper } from "./default-wrapper";

const MainPage = () => {
  return (
    <DefaultWrapper>
      <HomePage />
    </DefaultWrapper>
  );
};

export default MainPage;
