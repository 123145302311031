import {styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Image = styled('img')(() => ({
   width: '100%',
   borderRadius: 12,
   height: 230,
   objectFit: 'cover',
  }));

export const LinkStyled = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

export const ArticeWrapper = styled('div')(() => ({
    maxWidth: 778,
    width: '100%',
    margin: '46px auto 0',
}));

export const ArticeContent = styled('div')(() => ({
    width: '100%',
    display: 'grid',
    gap: 12,
    padding: '0 16px',

    '& img': {
        width: '100%',
        borderRadius: 12,
        display: 'block',
    },

    '& p': {
        color: '#C0C5D0',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px'
    },

    '& a': {
        color: '#C0C5D0',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px'
    },

    '& h2,h3,h4,h5': {
        color: '#C0C5D0',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px'
    },

    '& ul': {
        color: '#C0C5D0',
        fontSize: 16,
        marginLeft: 18,
    }
}));


