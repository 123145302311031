import { FC, memo } from "react";
import { Careers } from "../feature/сareers";
import { HelmetTags } from "../components/helmet";

const CareersPage: FC = memo(() => {
  return (
    <>
      <HelmetTags url={window.origin} />
      <Careers />
    </>
  );
});

export default CareersPage;
