
// @ts-nocheck
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

type FormReturn = {
  values: Record<string, any>;
  onSubmit: (
    e: React.SyntheticEvent<EventTarget>,
    callback: (values: Record<string, any>) => void,
  ) => void;
  onFieldChange: (e: React.ChangeEvent<any>) => void;
  resetForm: () => void;
  errors: Record<string, boolean>;
  setErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
};

export interface IInitValues {
  formInitValues: Record<string, any>;
  errorsInitValues?: Record<string, boolean>;
}

export function useForm({ formInitValues, errorsInitValues }: IInitValues): FormReturn {
  const [values, setValues] = useState(formInitValues);
  const [errors, setErrors] = useState(errorsInitValues);

  const onFieldChange = (e: React.ChangeEvent<any>): void => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      [e.target.name]: false,
    }));
  };
  const resetForm = useCallback(() => setValues(formInitValues), [formInitValues]);
  const onSubmit = useCallback(
    (
      e: React.SyntheticEvent<EventTarget>,
      callback: (values: Record<string, any>) => void,
    ): void => {
      e.preventDefault();
      callback(values);
    },
    [values],
  );

  return { values, onSubmit, onFieldChange, resetForm, errors, setErrors };
}
