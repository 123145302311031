import { FC, memo } from "react";
import { Title, Wrapper } from "./styled";
import { ButtonStyled, DecorLine } from "../../styled";
import { Line } from "../../../../assets/tsx/line";
import { Badge } from "../../../../components/badge";
import { useIsMobile } from "../../../../utils/use-is-mobile";

export const Whitepapper: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleLink = () =>
    window.open("https://docs.gammaprime.fi/untitled", "_blank");
  return (
    <Wrapper id="whitepaper">
      <DecorLine sx={{ height: 496, right: "0%", top: "-23%" }} delay={3}>
        <Line height={161} style={{ position: "relative", top: "60%" }} />
      </DecorLine>

      <DecorLine sx={{ height: 496, left: "0%", top: "1%" }} delay={0.5}>
        <Line height={161} style={{ position: "relative", top: "40%" }} />
      </DecorLine>
      <Badge text="WHITEPAPER" type="secondary" size="medium" />

      <Title
        variant="h3"
        fontSize={isMobile ? "32px" : "48px"}
        fontWeight={500}
        letterSpacing="-2.4px"
        lineHeight="110%"
        maxWidth={762}
        textAlign="center"
      >
        Tokenizing Real Yield in Web3 through Crypto structured products and
        derivatives,{" "}
        <span>anchored by institutional grade risk management.</span>
      </Title>

      <ButtonStyled
        variant="outlined"
        onClick={handleLink}
        width={isMobile ? "100%" : 210}
        sx={{ marginTop: isMobile ? "44px" : "53px" }}
      >
        Read Documentation
      </ButtonStyled>
    </Wrapper>
  );
});

Whitepapper.displayName = "Whitepapper";
