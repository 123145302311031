import { FC, memo } from "react";
import Talos from "../assets/png/talos.png";
import Stover from "../assets/png/stover.png";
import HiddenRoad from "../assets/png/hidden-road.png";
import Cole from "../assets/png/cole-frieman.png";
import BlockFills from "../assets/png/blockfills.png";
import { FlexCenteredVertically } from "../styles/styled-component";
import LazyImage from "./lazy-image";

export const Logos: FC = memo(() => {
  return (
    <FlexCenteredVertically
      gap="43px"
      padding="20px 0 0"
      flexWrap="wrap"
      justifyContent="center"
    >
      <LazyImage src={Talos} alt="Talos" />
      <LazyImage src={Stover} alt="Stover" />
      <LazyImage src={HiddenRoad} alt="HiddenRoad" />
      <LazyImage src={Cole} alt="Cole" />
      <LazyImage src={BlockFills} alt="BlockFills" />
    </FlexCenteredVertically>
  );
});
