import { FC, memo, useCallback } from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { Typography } from "@mui/material";
import { Image, TextWrapper, Text } from "./styled";
import { ButtonStyled, DecorLine, LineWrap } from "../home/styled";
import сareers from "../../assets/png/сareers.png";
import сareersMob from "../../assets/png/сareers-mob.png";
import { Form } from "../../components/form";
import { Badge } from "../../components/badge";

import { useIsMobile } from "../../utils/use-is-mobile";
import { Line } from "../../assets/tsx/line";
import ScrollToTop from "../../hooks/scrol-to-top";
import { CareersPositions } from "./positions";
import { Logos } from "../../components/logos";

export const Careers: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleScroll = useCallback((event: any) => {
    event.preventDefault();
    const section = document.getElementById("positions");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div>
      <ScrollToTop />
      <Header />
      <FlexCenteredVertically
        flexDirection="column"
        marginTop={isMobile ? "20px" : "87px"}
        padding={isMobile ? "0 16px" : "0px"}
        position="relative"
      >
        <LineWrap sx={{ left: "20%", top: "2%" }} delay={0.4}>
          <Line height={161} />
        </LineWrap>

        <LineWrap sx={{ right: "20%", top: "-2%" }} delay={0.9}>
          <Line height={161} />
        </LineWrap>
        <Badge text="CAREERS" type="secondary" size="medium" />

        <Typography
          fontSize={isMobile ? "42px" : "64px"}
          maxWidth="841px"
          marginTop={isMobile ? "18px" : "20px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing={isMobile ? "-2.94px" : "-4.48px"}
          textAlign="center"
        >
          Join the team changing the future of web3
        </Typography>

        <Typography
          variant={isMobile ? "body2" : "body1"}
          marginTop={isMobile ? "16px" : "24px"}
          lineHeight="140%"
          letterSpacing="-0.48px"
          sx={{ opacity: 0.6 }}
        >
          Browse open positions and find your dream job
        </Typography>

        <ButtonStyled
          onClick={handleScroll}
          variant="contained"
          width={210}
          sx={{ marginTop: isMobile ? "32px" : "24px" }}
        >
          Check Positions
        </ButtonStyled>

        <Image
          src={isMobile ? сareersMob : сareers}
          alt="сareers"
          sx={{ marginBottom: "82px" }}
        />

        <Logos />

        <TextWrapper>
          <DecorLine sx={{ height: 496, right: "5%", top: "-23%" }} delay={3}>
            <Line height={161} style={{ position: "relative", top: "60%" }} />
          </DecorLine>
          <Badge text="OUR VISION" type="primary" size="medium" />
          <Text
            variant="h3"
            fontSize={isMobile ? "32px" : "48px"}
            fontWeight={500}
            letterSpacing="-2.4px"
            lineHeight="110%"
            maxWidth={isMobile ? "292px" : "762px"}
            marginTop={isMobile ? "20px" : "15px"}
          >
            Tokenizing Real Yield in Web3 through Crypto structured products and
            derivatives,{" "}
            <span>anchored by institutional grade risk management.</span>
          </Text>
        </TextWrapper>
        <CareersPositions />
      </FlexCenteredVertically>

      <Form title="Join the team changing the future of web3" withMessage />
      <Footer />
    </div>
  );
});
