import { apiBlog } from "./../services/blog";
import { mainApi } from "./../services/api";
import { configureStore } from "@reduxjs/toolkit";
import { gammaApi } from "../services/gama-data";

export const store = configureStore({
	reducer: {
		[apiBlog.reducerPath]: apiBlog.reducer,
		[mainApi.reducerPath]: mainApi.reducer,
		[gammaApi.reducerPath]: gammaApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiBlog.middleware, mainApi.middleware, gammaApi.middleware),
});
