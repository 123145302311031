import { styled } from '@mui/material'
import { flexBoth, flexCenteredBetween, flexHorizontally } from './adaptivity'

interface IFlexBoxProps {
  className?: string
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  margin?: string
  marginRight?: string
  marginLeft?: string
  marginTop?: string
  marginBottom?: string
  transform?: string
  alignItems?: string
  justifyContent?: string
  padding?: string
  hover?: Record<string, string>
  flex?: string
  flexWrap?: 'wrap' | 'nowrap'
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  zIndex?: number
  overflowX?: 'scroll' | 'hidden' | 'visible'
  overflowY?: 'scroll' | 'hidden' | 'visible'
  overflow?: 'scroll' | 'hidden' | 'visible'
  background?: string
  borderRadius?: string
  gap?: string
  minWidth?: string
  minHeight?: string
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
  top?: string
  bottom?: string
  left?: string
  right?: string
  border?: string
  borderBottom?: string
  borderTop?: string
  alignSelf?: string
  boxSizing?: 'content-box' | 'border-box'
  boxShadow?: string
  float?: 'left' | 'right' | 'none' | 'inline-start' | 'inline-end'
  opacity?: string
  userSelect?: 'auto' | 'text' | 'none' | 'contain' | 'all'
  backdropFilter?: string
  flexShrink?: number
}

export const FlexCenteredVertically = styled('div')<IFlexBoxProps>(
  ({
    height,
    width,
    maxWidth,
    maxHeight,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    transform,
    alignItems,
    justifyContent,
    padding,
    hover,
    flex,
    flexWrap,
    flexDirection,
    zIndex,
    overflowX,
    overflowY,
    overflow,
    background,
    borderRadius,
    gap,
    minWidth,
    minHeight,
    position,
    top,
    bottom,
    left,
    right,
    border,
    borderBottom,
    borderTop,
    alignSelf,
    boxSizing,
    boxShadow,
    float,
    opacity,
    userSelect,
    backdropFilter,
    flexShrink,
  }) => ({
    display: 'flex',
    height,
    width,
    maxWidth,
    maxHeight,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    transform,
    alignItems: alignItems || 'center',
    justifyContent,
    padding,
    flex,
    flexWrap,
    flexDirection,
    zIndex,
    overflowX,
    overflowY,
    overflow,
    background,
    borderRadius,
    gap,
    minWidth,
    minHeight,
    position,
    top,
    bottom,
    left,
    right,
    border,
    borderBottom,
    borderTop,
    alignSelf,
    boxSizing,
    boxShadow,
    float,
    opacity,
    userSelect,
    backdropFilter,
    flexShrink,
    '&:hover': hover,
  }),
)

export const FlexCenteredBothAxises = styled('div')({
  ...flexBoth,
})

export const FlexCenteredBetween = styled('div')({
  ...flexCenteredBetween,
})

export const FlexCenteredHorizontally = styled('div')({
  ...flexHorizontally,
})
