import { FC, memo } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Form } from "../components/form";
import { CareersPositions } from "./сareers/positions";
import ScrollToTop from "../hooks/scrol-to-top";
import { FlexCenteredVertically } from "../styles/styled-component";
import { useIsMobile } from "../utils/use-is-mobile";
import { LineWrap } from "./home/styled";
import { Line } from "../assets/tsx/line";
import { Badge } from "../components/badge";
import { Typography, styled } from "@mui/material";
import { Image } from "../feature/сareers/styled";
import aboutUs from "../assets/png/about-us.png";
import aboutUsMob from "../assets/png/about-us-mob.png";
import { forMobile } from "../styles/adaptivity";
import { Team } from "../components/team";
import { HelmetTags } from "../components/helmet";

export const Wrapper = styled("div")(() => ({
  maxWidth: 1306,
  width: "100%",
  position: "relative",
  margin: "0 auto",

  [forMobile]: {
    padding: "0 16px",
  },
}));

export const AboutUs: FC = memo(() => {
  const isMobile = useIsMobile();
  return (
    <div>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      <Wrapper>
        <FlexCenteredVertically
          flexDirection="column"
          marginTop={isMobile ? "20px" : "87px"}
          position="relative"
        >
          <LineWrap sx={{ left: "20%", top: "2%" }} delay={0.4}>
            <Line height={161} />
          </LineWrap>

          <LineWrap sx={{ right: "20%", top: "-2%" }} delay={0.9}>
            <Line height={161} />
          </LineWrap>
          <Badge text="ABOUT US" type="secondary" size="medium" />

          <Typography
            fontSize={isMobile ? "42px" : "64px"}
            maxWidth={721}
            marginTop={isMobile ? "18px" : "20px"}
            fontWeight={500}
            lineHeight="93%"
            letterSpacing={isMobile ? "-2.94px" : "-4.48px"}
            textAlign="center"
          >
            Bringing decades of experience into web3
          </Typography>

          <Typography
            variant={isMobile ? "body2" : "body1"}
            marginTop={isMobile ? "16px" : "24px"}
            lineHeight="140%"
            letterSpacing="-0.48px"
            sx={{ opacity: 0.6 }}
          >
            Browse open positions and find your dream job
          </Typography>
        </FlexCenteredVertically>
      </Wrapper>

      <FlexCenteredVertically flexDirection="column">
        <Image
          sx={{ marginBottom: "100px" }}
          src={isMobile ? aboutUsMob : aboutUs}
          alt="сareers"
        />
      </FlexCenteredVertically>

      <Wrapper>
        <FlexCenteredVertically flexDirection="column">
          <Team isTeam />
        </FlexCenteredVertically>

        <CareersPositions />
      </Wrapper>

      <Form
        title={"Join the team changing the future of web3"}
        badge="Join Beta"
        withMessage
      />
      <Footer />
    </div>
  );
});
