import { FC, memo } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Typography, styled } from "@mui/material";
import { forMobile } from "../styles/adaptivity";
import ScrollToTop from "../hooks/scrol-to-top";
import { HelmetTags } from "../components/helmet";

const Container = styled("div")(() => ({
  maxWidth: 800,
  margin: "0 auto",

  "& ul": {
    paddingLeft: 20,
    marginTop: 20,
  },

  "& li": {
    marginTop: 10,
    listStyle: "disc",

    "&::marker": {
      color: "#FFF",
    },
  },

  "& a": {
    color: "#FFF",
  },

  [forMobile]: {
    padding: "0 16px",
  },
}));

const PrivacyPoilicy: FC = memo(() => {
  return (
    <div>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      <Container>
        <Typography
          variant="h3"
          fontSize={"48px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-3.36px"
          marginTop="100px"
        >
          Privacy Poilicy
        </Typography>

        <Typography
          fontSize={24}
          fontWeight={300}
          lineHeight="93%"
          letterSpacing={-1.68}
          marginTop="16px"
        >
          Effective date: June 4th, 2024
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
          color="#43D090"
        >
          By using or accessing the Services in any manner, you acknowledge that
          you accept the practices and policies outlined in this Privacy Policy,
          and you hereby consent that we will collect, use, and share your
          information in the following ways.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What does this Privacy Policy cover?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          This Privacy Policy covers our treatment of personally identifiable
          information <strong>(“Personal Information”)</strong> that we gather
          when you are accessing or using our Services, but not to the practices
          of companies we don’t own or control, or people that we don’t manage.
          We gather various types of Personal Information from our users, as
          explained in more detail below, and we use this Personal Information
          internally in connection with our Services, including to personalize,
          provide, and improve our services, to fulfill your requests for
          certain products and services, and to analyze how you use the
          Services. In certain cases, we may also share some Personal
          Information with third parties, but only as described below. If you
          are under the age of majority in your jurisdiction of residence, you
          may use the Services only with the consent of or under the supervision
          of your parent or legal guardian. Consistent with the requirements of
          the Children's Online Privacy Protection Act (COPPA), if we learn that
          we have received any information directly from a child under age 13
          without first receiving his or her parent's verified consent, we will
          use that information only to respond directly to that child (or his or
          her parent or legal guardian) to inform the child that he or she
          cannot use our Services and subsequently we will delete that
          information.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          Will Gamma Prime ever change this Privacy Policy?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We're constantly trying to improve our Services and how we approach
          privacy matters, so we may need to change this Privacy Policy from
          time to time, but we will alert you to changes by requiring you to log
          in to our Services again and accept the new Privacy Policy and/or by
          some other means. If you use the Services after any changes to the
          Privacy Policy have been posted, that means you agree to all of the
          changes. Use of information we collect now is subject to the Privacy
          Policy in effect at the time such information is used.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What Information does Gamma Prime Collect?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          <strong>Information You Provide to Us:</strong> We receive and store
          any information you knowingly provide to us. For example, this
          includes information you provide when you import your Wallet such as
          information regarding your transactional history on a Blockchain
          network, information you provide each time you wish to make a transfer
          of Virtual Currency through the Services, or usage information, such
          as information about how you use the Service and interact with us. It
          also includes contact information you elect to provide to us, such as
          your email address, feedback and correspondence, such as information
          you provide in your responses to surveys, when you participate in
          market research activities, report a problem with Service, receive
          customer support or otherwise correspond with us, and marketing
          information, such your preferences for receiving marketing
          communications and details about how you engage with them.{" "}
          <strong>
            You should understand that, because of the nature of most Blockchain
            networks, your entire transactional history conducted from your
            Wallet is provided to us or otherwise publicly accessible and is
            necessary to support your usage of the Services.
          </strong>{" "}
          <br />
          <strong>Information Collected Automatically:</strong> Whenever you
          interact with our Services, we automatically receive and record
          information on our server logs from your browser or device, which may
          include your IP address, geolocation data, device identification,{" "}
          <strong>“cookie”</strong> information, the type of browser and/or
          device you’re using to access our Services, and the page or feature
          you requested. “Cookies” are identifiers we transfer to your browser
          or device that allow us to recognize your browser or device and tell
          us how and when pages and features in our Services are visited and by
          how many people. You may be able to change the preferences on your
          browser or device to prevent or limit your device’s acceptance of
          cookies, but this may prevent you from taking advantage of some of our
          features. If you click on a link to a third-party website or service,
          such third party may also transmit cookies to you. Again, this Privacy
          Policy does not cover the use of cookies by any third parties, and we
          aren’t responsible for their privacy policies and practices. Please be
          aware that cookies placed by third parties may continue to track your
          activities online even after you have left our Services, and those
          third parties may not honor “Do Not Track” requests you have set using
          your browser or device. We may use this data to customize content for
          you that we think you might like, based on your usage patterns. We may
          also use it to improve the Services - for example, this data can tell
          us how often users use a particular feature of the Services, and we
          can use that knowledge to make the Services interesting to as many
          users as possible.
          <strong>
            Information Collected from Other Websites and Do Not Track Policy:
          </strong>{" "}
          Through cookies we place on your browser or device, we may collect
          information about your online activity after you leave our Services.
          Just like any other usage information we collect, this information
          allows us to improve the Services and customize your online
          experience, and otherwise as described in this Privacy Policy. Your
          browser may offer you a “Do Not Track” option, which allows you to
          signal to operators of websites and web applications and services
          (including behavioral advertising services) that you do not wish such
          operators to track certain of your online activities over time and
          across different websites. When you turn on the Do Not Track function
          in your browser, we stop collecting the information from your browser
          that allows us to tailor advertisements and other content specifically
          to you that is based on your visits to our advertising partners’
          websites. Specifically, we stop collecting information from the unique
          browser cookie that links your browser to visits to third party sites.
          Do Not Track signals are set on a browser-bybrowser basis, so you must
          set them on every browser you use if you do not wish to be tracked.
          Remember that this is just our Do Not Track policy, and we can’t and
          don’t make any promises about how third parties react when you set
          this signal on your browser.
          <strong>Information we will never collect:</strong> We will never ask
          you to share your private keys to your Wallet or other security
          information that could be used to access your Wallet without your
          explicit consent and action. Never trust anyone or any site that asks
          you to enter your private keys or similar security information.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          Will Gamma Prime Share Any of the Personal Information it Receives?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We do not share or sell the personal information that you provide us
          with other organizations without your express consent, except as
          described in this Privacy Policy. We may share your Personal
          Information with third parties as described below:
          <br />
          <br />
          <ul>
            <li>
              <strong>Affiliated Businesses:</strong> In certain situations,
              businesses or third-party websites we’re affiliated with may sell
              or provide products or services to you through or in connection
              with the Services (either alone or jointly with us). You can
              recognize when an affiliated business is associated with such a
              transaction or service, and we will share your Personal
              Information with that affiliated business only to the extent that
              it is related to such transaction or service. We have no control
              over the policies and practices of third-party websites or
              businesses as to privacy or anything else, so if you choose to
              take part in any transaction or service relating to an affiliated
              website or business, please review all such business’ or websites’
              policies.
            </li>{" "}
            <br />
            <li>
              <strong>Our Agents:</strong> We employ other companies and people
              to perform tasks on our behalf and need to share your information
              with them to provide products or services to you or to us. Unless
              we tell you differently, our agents do not have any right to use
              the Personal Information we share with them beyond what is
              necessary to assist us.
            </li>{" "}
            <br />
            <li>
              <strong>Business Transfers:</strong> We may choose to buy or sell
              assets and may share and/or transfer customer information in
              connection with the evaluation of and entry into such
              transactions. Also, if we (or our assets) are acquired, or if we
              go out of business, enter bankruptcy, or go through some other
              change of control, Personal Information could be one of the assets
              transferred to or acquired by a third party.
            </li>
            <br />
            <li>
              <strong>Third Party Services:</strong> We may use third party
              services, such as Google Analytics, to grow our business, to
              improve and develop our Services, to monitor and analyze use of
              our Services, to aid our technical administration, to increase the
              functionality and user-friendliness of our Services, and to verify
              that users have the authorization needed for us to process their
              requests. These services may collect and retain some information
              about you, such as the IP address assigned to you on the date you
              use the Services, but not your name or other personally
              identifying information. We may combine the information generated
              through the use of these services with your Personal Information
              but never in a way that will identify you to any other user.
              Although these services may plant a persistent cookie on your web
              browser to identify you as a unique user the next time you use the
              Services, the cookie cannot be used by anyone but Google. These
              services’ ability to use and share information about your use of
              the Services is restricted by the Google Analytics Terms of
              Service and the Google Privacy Policy. You may find additional
              information about Google Analytics at{" "}
              <a
                target="_blank"
                href="https://google.com/policies/privacy/partners/"
                rel="noreferrer"
              >
                https://google.com/policies/privacy/partners/
              </a>
              . You can opt out of Google Analytics by visiting{" "}
              <a
                target="_blank"
                href="https://tools.google.com/dlpage/gaoptout/"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout/
              </a>
              .
            </li>
            <br />
            <li>
              <strong>Protection of Gamma Prime and Others:</strong> We reserve
              the right to access, read, preserve, and disclose any information
              that we believe is necessary to comply with law or court order;
              protect the rights, property, or safety of Gamma Prime, our
              employees, our users, or others.
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          EU Residents
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          If you are a resident of the European Economic Area (“EEA”), you may
          have additional rights under the General Data Protection Regulation
          (the “GDPR” ) and other applicable law with respect to your Personal
          Data, as outlined below. For this section, we use the terms “Personal
          Data” and “processing” as they are defined in the GDPR, but “Personal
          Data” generally means information that can be used to individually
          identify a person, and “processing” generally covers actions that can
          be performed in connection with data such as collection, use, storage
          and disclosure. Gamma Prime will be the controller of your Personal
          Data processed in connection with the Services. If there are any
          conflicts between this section and any other provision of this Privacy
          Policy, the policy or portion that is more protective of Personal Data
          shall control to the extent of such conflict. If you have any
          questions about this section or whether any of the following applies
          to you, please contact us at{" "}
          <a href="malito:info@gammaprime.fi">info@gammaprime.fi</a>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What Personal Data Do We Collect from You?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We collect Personal Data about you when you provide such information
          directly to us, when third parties such as our business partners or
          service providers provide us with Personal Data about you, or when
          Personal Data about you is automatically collected in connection with
          your use of our Services.
          <br />
          <br />
          <ul>
            <li>
              Information we collect directly from you:We receive Personal Data
              directly from you when you provide us with such Personal Data,
              including without limitation the following:
              <ul>
                <li>
                  Public key data for your Wallet and associated transactions on
                  a Blockchain network
                </li>
                <li>E-Mail address</li>
                <li>
                  Other information you may elect to submit in your
                  communications with us while using our Services, such as your
                  name in connection with providing feedback or user
                  testimonials.
                </li>
              </ul>
            </li>

            <li>
              Information we automatically collect when you use our Services:
              Some Personal Data is automatically collected when you use our
              Services, such as the following:
              <br />
              <ul>
                <li>IP address</li>
                <li>Device identifiers</li>
                <li>Web browser information</li>
                <li>Page view statistics</li>
                <li>Browsing history</li>
                <li>Usage information</li>
                <li>
                  Transaction information (e.g. transaction amount, date and
                  time such transaction occurred)
                </li>
                <li>Cookies and other tracking technologies</li>
                <li>
                  Log data (e.g. access times, hardware and software
                  information)
                </li>
              </ul>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          How Do We Use Your Personal Data?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We process Personal Data to operate, improve, understand and
          personalize our Services. For example, we use Personal Data to:
          <br />
          <br />
          <ul>
            <li>
              Enable access to decentralized protocols and dapps using your
              Wallet through our Interface or App
            </li>
            <li>
              Communicate with you to process transactions to and from your
              Wallet
            </li>
            <li>Provide support and assistance for the Services</li>
            <li>
              Personalize website content and communications based on your
              preferences
            </li>
            <li>Comply with our contractual or legal obligations</li>
            <li>Respond to user inquiries</li>
            <li>Resolve disputes</li>
            <li>
              Protect against or deter fraudulent, illegal or harmful actions
            </li>
          </ul>
          <br />
          We will only process your Personal Data if we have a lawful basis for
          doing so. Lawful bases for processing include consent, contractual
          necessity and our “legitimate interests” or the legitimate interest of
          others, as further described below.
          <br />
          <br />
          <ul>
            <li>
              Contractual Necessity: We process the following categories of
              Personal Data as a matter of “contractual necessity”, meaning that
              we need to process the data, which enables us to provide you with
              the Services. When we process data due to contractual necessity,
              failure to provide such Personal Data will result in your
              inability to use some or all portions of the Services that require
              such data.
              <ul>
                <li>
                  Public key data for your Wallet and associated transactions on
                  a Blockchain network
                </li>
                <li>E-Mail address</li>
              </ul>
            </li>

            <li>
              Legitimate Interest: We consider and balance any potential impacts
              on you (both positive and negative) and your rights before we
              process your Personal Data for our legitimate interests. We do not
              use your Personal Data for activities where our interests are
              overridden by any adverse impact on you (unless we have your
              consent or are otherwise required or permitted to by law). We
              process the following categories of Personal Data when we believe
              it furthers the legitimate interest of us or third parties.
              <br />
              <ul>
                <li>IP address</li>
                <li>Device identifiers</li>
                <li>Web browser information</li>
                <li>Page view statistics</li>
                <li>Browsing history</li>
                <li>Usage information</li>
                <li>
                  Transaction information (e.g. transaction amount, date and
                  time such transaction occurred)
                </li>
                <li>Cookies and other tracking technologies</li>
                <li>
                  Log data (e.g. access times, hardware and software
                  information)
                </li>
              </ul>
            </li>

            <li>
              Examples of these legitimate interests include:
              <ul>
                <li>
                  Operation and improvement of our business, products and
                  Services
                </li>
                <li>Marketing of our products and Services</li>
                <li>Provision of customer support</li>
                <li>Protection from fraud or security threats</li>
                <li>Compliance with legal obligations</li>
                <li>Completion of corporate transactions</li>
              </ul>
            </li>

            <li>
              Consent: In some cases, we process Personal Data based on the
              consent you expressly grant to us at the time we collect such
              data. When we process Personal Data based on your consent, it will
              be expressly indicated to you at the point and time of collection.
            </li>

            <li>
              Other Processing Grounds: From time to time we may also need to
              process Personal Data to comply with a legal obligation, if it is
              necessary to protect the vital interests of you or other data
              subjects, or if it is necessary for a task carried out in the
              public interest.
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          How and With Whom Do We Share Your Data?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We share Personal Data with vendors, third party service providers and
          agents who work on our behalf and provide us with services related to
          the purposes described in this Privacy Policy. These parties include:
          <br />
          <br />
          <ul>
            <li>Payment processors</li>
            <li>Fraud prevention service providers</li>
            <li>Analytics service providers</li>
            <li>Staff augmentation and contract personnel</li>
            <li>Hosting service providers</li>
            <li>Co-location service providers</li>
          </ul>
          <br />
          We also share Personal Data when we believe it is necessary to:
          <ul>
            <li>
              Comply with applicable law or respond to valid legal process,
              including from law enforcement or other government agencies
            </li>
            <li>
              Protect us, our business or our users, for example to prevent spam
              or other unwanted communications and investigate or protect
              against fraud
            </li>
            <li>Maintain the security of our products and Services</li>
          </ul>
          <br />
          We also share information with third parties when you give us consent
          to do so. Furthermore, if we choose to buy or sell assets, user
          information is typically one of the transferred business assets.
          Moreover, if we, or substantially all of our assets, were acquired, or
          if we go out of business or enter bankruptcy, user information would
          be one of the assets that is transferred or acquired by a third party,
          and we would share Personal Data with the party that is acquiring our
          assets. You acknowledge that such transfers may occur, and that any
          acquirer of us or our assets may continue to use your Personal
          Information as set forth in this policy.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          How Long Do We Retain Your Personal Data?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We retain Personal Data about you for as long as necessary to provide
          you Services. In some cases, we retain Personal Data for longer, if
          doing so is necessary to comply with our legal obligations, resolve
          disputes or collect fees owed, or is otherwise permitted or required
          by applicable law, rule or regulation. Afterwards, we may retain some
          information in a depersonalized or aggregated form but not in a way
          that would identify you personally.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What Security Measures Do We Use?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          We seek to protect Personal Data using appropriate technical and
          organizational measures based on the type of Personal Data and
          applicable processing activity, and, where possible, we refrain from
          collecting Personal Data. For example, Gamma Prime does not collect
          private key data associated with your Wallet, and we secure the
          Personal Data you provide in a controlled, secure environment and
          protected from unauthorized access. Gamma Prime regularly audits its
          system for any possible security vulnerabilities to safeguard Personal
          Data. Gamma Prime protects the security of your information during
          transmission by using Transport Layer Security (TSL/SSL), which
          encrypts all information that you input. However, your private key is
          the key to your Wallet. If you do share your private key or your
          Personal Data with others that enables them to access your private
          key, you remain responsible for all actions taken in the name of your
          Wallet. If you lose control of your private key, you may lose control
          over your Wallet or your Personal Data and may be subject to legally
          binding actions taken of your behalf.
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What Rights Do You Have Regarding Your Personal Data?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          You have certain rights with respect to your Personal Data, including
          those set forth below. For more information about these rights, or to
          submit a request, please email info@gammaprime.fi. Please note that in
          some circumstances, we may not be able to fully comply with your
          request, such as if it is frivolous or extremely impractical, if it
          jeopardizes the rights of others, or if it is not required by law, but
          in those circumstances, we will still respond to notify you of such a
          decision. In some cases, we may also need to you to provide us with
          additional information, which may include Personal Data, if necessary,
          to verify your identity and the nature of your request.
          <br />
          <br />
          <ul>
            <li>
              Access: You can request more information about the Personal Data
              we hold about you and request a copy of such Personal Data. You
              can also access certain of your Personal Data by visiting Gamma
              Prime’s Interface.
              <ul>
                <li>
                  Rectification: If you believe that any Personal Data, we are
                  holding about you is incorrect or incomplete, you can request
                  that we correct or supplement such data. You can correct some
                  of this information directly by editing them on Gamma Prime’s
                  Interface.
                </li>
                <li>
                  Erasure: You can request that we erase some or all your
                  Personal Data from our systems, provided that this will not
                  erase any Personal Data you have submitted on a Blockchain
                  network that isn’t controlled by us.
                </li>
                <li>
                  Withdrawal of Consent: If we are processing your Personal Data
                  based on your consent (as indicated at the time of collection
                  of such data), you have the right to withdraw your consent at
                  any time. Please note, however, that if you exercise this
                  right, you may have to then provide express consent on a
                  case-by-case basis for the use or disclosure of certain of
                  your Personal Data, if such use or disclosure is necessary to
                  enable you to utilize some or all of our Services.
                </li>
                <li>
                  Portability: You can ask for a copy of your Personal Data in a
                  machine-readable format. You can also request that we transmit
                  the data to another controller where technically feasible.
                </li>
                <li>
                  Objection: You can contact us to let us know that you object
                  to the further use or disclosure of your Personal Data for
                  certain purposes.
                </li>
                <li>
                  Restriction of Processing: You can ask us to restrict further
                  processing of your Personal Data.
                </li>
                <li>
                  Right to File Complaint: You have the right to lodge a
                  complaint about Gamma Prime’s practices with respect to your
                  Personal Data with the supervisory authority of your country
                  or European Union Member State.
                </li>
              </ul>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What If You Have Questions Regarding Your Personal Data?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          If you have any questions about this section or our data practices
          generally, please contact us using the following information:
          <br />
          <br />
          <ul>
            <li>
              Email address for contact:{" "}
              <a href="malito:info@gammaprime.fi">info@gammaprime.fi</a>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          fontSize={"24px"}
          fontWeight={500}
          lineHeight="93%"
          letterSpacing="-1.68px"
          marginTop="48px"
        >
          What if I have questions about this policy?
        </Typography>

        <Typography
          variant="body1"
          lineHeight="150%"
          letterSpacing="-0.32px"
          marginTop="48px"
          sx={{ opacity: 0.8 }}
        >
          If you have any questions or concerns regarding our privacy policies,
          please send us a detailed message to{" "}
          <a href="malito:info@gammaprime.fi">info@gammaprime.fi</a>, and we
          will try to resolve your concerns.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
});

export default PrivacyPoilicy;
