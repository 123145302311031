import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiKey, blogUrl } from '../utils/constants';
import { ApiBlogResponse } from './type';

export const apiBlog = createApi({
  reducerPath: 'apiBlog',
  baseQuery: fetchBaseQuery({
    baseUrl: blogUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      headers.set('Authorization', `Bearer ${apiKey}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getArticles: builder.query<ApiBlogResponse, null>({
        query: () => `/api/articles?populate=*`,
    }),

    getArticleById: builder.query<ApiBlogResponse, {id: string}>({
        query: ({id}) => `/api/articles?filters[slug][$eq]=${id}&populate=*`,
    }),

    getArticlesByCategories: builder.query<ApiBlogResponse, {category: string}>({
        query: ({category}) => `/api/articles?filters[category][slug][$eq]=${category}&populate=*`,
    }),
  }),
});

export const { useGetArticlesQuery, useGetArticleByIdQuery, useGetArticlesByCategoriesQuery } = apiBlog;