import { styled } from "@mui/material";
import { forMobile } from "../../styles/adaptivity";
import { Link } from "react-router-dom";

export const FooterStyled = styled('div')(() => ({
    background: '#08091D',
    padding: '48px 0 50px',

    [forMobile]: {
        padding: '28px 16px 52px'
    }
}))

export const FooterContainer = styled('div')(() => ({
    maxWidth: 1312,
    margin: '0 auto'
}))

export const LinkStyledFooter = styled(Link)(() => ({
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecoration: 'none',
    gap: 14,
}))