import { styled } from "@mui/material";
import { forMobile } from "../../../../styles/adaptivity";

export const Wrapper = styled('div')(() => ({
    marginTop: 178,
    maxWidth: '100%',


    '& .swiper-container-free-mode > .swiper-wrapper': {
        transitionTimingFunction : 'linear',
    },

    '& .swiper-slide': {
      width: 'auto !important',
    },

    [forMobile]: {
      marginTop: 80,
    },

    '@keyframes scroll': {
      '0%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      }
    },
    
    '& .swiper-container': {
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    
    '& .swiper-wrapper': {
      display: 'flex',
      animation: 'scroll 30s linear infinite',
      alignItems: 'center',
    }
}))