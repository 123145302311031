import { Typography } from "@mui/material";
import { FC, memo } from "react";
import { FlexCenteredVertically } from "../styles/styled-component";
import LazyImage from "./lazy-image";
import { useIsMobile } from "../utils/use-is-mobile";
import { Badge } from "./badge";
import { firstTeam, secondTeam } from "../utils/data";
import ibm from "../assets/svg/ibm.svg";
import redbull from "../assets/svg/red-bull.svg";
import cme from "../assets/svg/cme.svg";
import mckenna from "../assets/svg/mckenna.svg";
import warren from "../assets/svg/warren.svg";
import yeti from "../assets/svg/yeti.svg";
import cboe from "../assets/svg/cboe.svg";
import dvtrading from "../assets/svg/dvtrading.svg";
import ey from "../assets/svg/ey.svg";
import amazon from "../assets/svg/amazon.svg";

interface IProps {
  name: string;
  photo: string;
  text: string;
}

const Persone: FC<IProps> = memo(({ name, photo, text }) => {
  return (
    <FlexCenteredVertically flexDirection="column">
      <LazyImage src={photo} alt={name} />
      <Typography
        fontSize={20}
        fontWeight={700}
        letterSpacing={-0.8}
        color="#FFF"
        lineHeight={"109%"}
        mt={"24px"}
        textAlign="center"
      >
        {name}
      </Typography>
      <Typography
        fontSize={16}
        fontWeight={400}
        letterSpacing={-0.48}
        color="#FFF"
        lineHeight={"140%"}
        mt={"12px"}
        sx={{ opacity: 0.6 }}
        maxWidth={365}
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </FlexCenteredVertically>
  );
});

const company1 = [cme, redbull, ibm, amazon];

const company2 = [mckenna, warren, cboe];

const company3 = [yeti, dvtrading, ey];

export const Team: FC<{ isTeam?: boolean }> = memo(({ isTeam }) => {
  const isMobile = useIsMobile();

  return (
    <FlexCenteredVertically flexDirection="column" position="relative">
      <Badge text="WHO’S BEHIND GAMMA?" type="primary" size="medium" />

      <Typography
        variant="h3"
        fontSize={isMobile ? "32px" : "48px"}
        fontWeight={500}
        letterSpacing={isMobile ? "-2.24px" : "-3.36px"}
        mt={"15px"}
        textAlign="center"
      >
        Chicago HFT: Early Days
      </Typography>

      <FlexCenteredVertically
        marginTop="64px"
        gap="24px"
        flexWrap="wrap"
        marginBottom="101px"
        justifyContent="center"
        alignItems="start"
      >
        {firstTeam.map((el) => (
          <Persone
            key={el.text}
            name={el.name}
            photo={el.photo}
            text={el.text}
          />
        ))}
      </FlexCenteredVertically>

      <Badge text="OTHER TEAM MEMBERS" type="primary" size="medium" />

      <Typography
        variant="h3"
        fontSize={isMobile ? "32px" : "48px"}
        fontWeight={500}
        letterSpacing={isMobile ? "-2.24px" : "-3.36px"}
        mt={"15px"}
        textAlign="center"
        maxWidth={672}
      >
        Veterans From Numerous Fortune 500 Titans
      </Typography>

      <Typography
        fontSize={16}
        fontWeight={500}
        letterSpacing={-0.48}
        mt={"64px"}
        textAlign="center"
        color="rgba(217, 217, 217, 0.42)"
      >
        Contributors to:
      </Typography>

      <FlexCenteredVertically
        flexWrap="wrap"
        gap={isMobile ? "50px" : "100px"}
        justifyContent="center"
        marginTop="20px"
        sx={{ maxWidth: 1060 }}
      >
        {company1.map((i) => (
          <img key={i} src={i} alt="" />
        ))}
      </FlexCenteredVertically>

      <FlexCenteredVertically
        flexWrap="wrap"
        gap={isMobile ? "32px" : "100px"}
        justifyContent="center"
        marginTop={isMobile ? "50px" : "24px"}
        sx={{ maxWidth: 1060 }}
      >
        {company2.map((i) => (
          <img key={i} src={i} alt="" />
        ))}
      </FlexCenteredVertically>

      <FlexCenteredVertically
        flexWrap="wrap"
        gap={isMobile ? "32px" : "100px"}
        justifyContent="center"
        marginTop={isMobile ? "50px" : "24px"}
        sx={{ maxWidth: 1060 }}
      >
        {company3.map((i) => (
          <img key={i} src={i} alt="" />
        ))}
      </FlexCenteredVertically>

      <FlexCenteredVertically
        marginTop="64px"
        gap="24px"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: "64px" }}
        maxWidth={isTeam ? "1050px" : "auto"}
        alignItems="start"
      >
        {secondTeam.map((el) => (
          <Persone
            key={el.text}
            name={el.name}
            photo={el.photo}
            text={el.text}
          />
        ))}
      </FlexCenteredVertically>
    </FlexCenteredVertically>
  );
});
