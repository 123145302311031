import {
  Box,
  FormControlLabel,
  Modal,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FC, memo, useEffect, useState } from "react";
import {
  FlexCenteredBothAxises,
  FlexCenteredVertically,
} from "../../styles/styled-component";
import { ButtonStyled, CustomBox, CustomRadio } from "./styled";
import { useSurveyMutation } from "../../services/api";
import close from "../../assets/svg/close.svg";

interface IProps {
  open: boolean;
  handleClose: () => void;
  email: string;
}

const questions = [
  {
    title: "",
    options: [""],
  },
  {
    title: "Your current portfolio size:",
    options: [
      "Less than $10,000",
      "$10,000 - $50,000",
      "$50,000 - $250,000",
      "$1,000,000+",
    ],
  },
  {
    title: "What % of your portfolio is in low-risk investments?",
    options: ["<20%", "20-50%", "50-75%", "75%+"],
  },
];

export const Survey: FC<IProps> = memo(({ open, handleClose, email }) => {
  const [selectedValueStep, setSelectedValueStep] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const isFinalStep = step > questions.length - 1;

  const [subscribe] = useSurveyMutation();

  const handleChangeStep = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValueStep(event.target.value);
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
    if (step > 0) {
      try {
        subscribe({
          email: email,
          question: questions[step].title,
          answer: selectedValueStep,
        })
          .unwrap()
          .then(() => {});
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setStep(0);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <CustomBox>
        <FlexCenteredBothAxises
          onClick={handleClose}
          sx={{ position: "absolute", top: 28, right: 24, cursor: "pointer" }}
        >
          <img src={close} alt="close" />
        </FlexCenteredBothAxises>

        {step === 0 || isFinalStep ? (
          <FlexCenteredVertically flexDirection="column">
            <Typography variant="h5">
              {isFinalStep
                ? "Thank you for your time!"
                : "Thank you for signing up"}
            </Typography>
            <Typography
              color="#A4A4A4"
              variant="body1"
              textAlign="center"
              mt="24px"
              maxWidth={408}
            >
              {isFinalStep
                ? "We will keep you updated about Gamma Prime news."
                : " We will keep you updated about Gamma Prime. Please fill out a short survey for increased priority."}
            </Typography>

            {!isFinalStep && (
              <ButtonStyled
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                onClick={handleNext}
              >
                Fill out a survey
              </ButtonStyled>
            )}

            <ButtonStyled
              fullWidth
              onClick={handleClose}
              variant={isFinalStep ? "contained" : "outlined"}
              sx={{ mt: isFinalStep ? "48px" : "12px" }}
            >
              {isFinalStep ? "Close" : "Skip for now"}
            </ButtonStyled>
          </FlexCenteredVertically>
        ) : (
          <FlexCenteredVertically flexDirection="column" alignItems="start">
            <Typography variant="h5">Survey</Typography>
            <Typography fontSize={18} mt={3}>
              {questions[step].title}
            </Typography>

            <Box bgcolor="#464F74" height="1px" mt={2} mb={2} width="100%" />

            <RadioGroup
              aria-label="portfolio-size"
              name="portfolio-size"
              value={selectedValueStep}
              onChange={handleChangeStep}
            >
              {questions[step].options.map((opt) => (
                <FormControlLabel
                  value={opt}
                  control={<CustomRadio />}
                  label={opt}
                />
              ))}
            </RadioGroup>

            <ButtonStyled
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleNext}
            >
              {step === 1 ? "Next" : "Submit"}
            </ButtonStyled>
          </FlexCenteredVertically>
        )}
      </CustomBox>
    </Modal>
  );
});
