import { FC, memo, useCallback } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Typography, styled } from "@mui/material";
import { Form } from "../components/form";
import arrow from "../assets/svg/arrow-narrow-up-right.svg";
import { forMobile } from "../styles/adaptivity";
import { useIsMobile } from "../utils/use-is-mobile";
import ScrollToTop from "../hooks/scrol-to-top";
import LazyImage from "../components/lazy-image";
import { HelmetTags } from "../components/helmet";

const Wrapper = styled("div")(() => ({
  [forMobile]: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: "0 16px",
  },

  "& .wrapper": {
    marginTop: "140px",
    [forMobile]: {
      padding: 16,
      borderRadius: 16,
      marginTop: "48px",
    },
  },

  "& .title": {
    [forMobile]: {
      fontSize: 32,
      letterSpacing: "-2.24px",
      textAlign: "left",
      lineHeight: "normal",
      marginBottom: 24,
    },
  },
}));

const Container = styled("div")(() => ({
  maxWidth: 1311,
  width: "100%",
  padding: "60px 0",
  margin: "0 auto",
  [forMobile]: {
    padding: "30px 0 0",
  },
}));

const Content = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: 20,
  marginTop: 48,

  [forMobile]: {
    gridTemplateColumns: "1fr",
    gap: 48,
  },
}));

const Link = styled("a")(() => ({
  color: "#39CA88",
  display: "flex",
  alignItems: "center",
  gap: 8,
  textDecoration: "none",
}));

const GetInTouch: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleScroll = useCallback((event: any, id: string) => {
    event.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      <Wrapper>
        <Form
          badge={isMobile ? "" : "JOIN BETA"}
          title="Let’s raise the effectiveness of web3 together"
        />

        <Container>
          <Typography
            variant="h3"
            fontSize={isMobile ? "32px" : "48px"}
            fontWeight={500}
            letterSpacing={isMobile ? "-2.24px" : "-3.36px"}
          >
            Contact Us
          </Typography>

          <Content>
            <div>
              <Typography
                fontSize={isMobile ? "20px" : "24px"}
                fontWeight={500}
                letterSpacing={isMobile ? "-1.4px" : "-1.68px"}
              >
                Do you have questions, issues or suggestions?
              </Typography>

              <Typography
                variant="body1"
                fontWeight={400}
                letterSpacing={"-1.12px"}
                marginTop="24px"
              >
                Use one of the Gamma Prime Community channels:
              </Typography>

              <Link
                target="_blank"
                href="https://t.me/gammaprime_fi"
                sx={{ marginTop: "24px" }}
              >
                <Typography
                  variant="body1"
                  color="#39CA88"
                  letterSpacing={"-0.32px"}
                >
                  Join Telegram
                </Typography>
                <LazyImage src={arrow} alt="arrow" />
              </Link>

              <Link
                target="_blank"
                href="https://x.com/gammaprime_fi"
                sx={{ marginTop: "16px" }}
              >
                <Typography
                  variant="body1"
                  color="#39CA88"
                  letterSpacing={"-0.32px"}
                >
                  Join Twitter
                </Typography>
                <LazyImage src={arrow} alt="arrow" />
              </Link>
            </div>

            <div>
              <Typography
                fontSize={isMobile ? "20px" : "24px"}
                fontWeight={500}
                letterSpacing={isMobile ? "-1.4px" : "-1.68px"}
              >
                You want to get directly in touch with the Gamma Prime
                Foundation? 
              </Typography>

              <Link
                onClick={(e) => handleScroll(e, "form")}
                sx={{ marginTop: "24px", cursor: "pointer" }}
              >
                <Typography
                  variant="body1"
                  color="#39CA88"
                  letterSpacing={"-0.32px"}
                >
                  Use this form requesting help as a project
                </Typography>
                <LazyImage src={arrow} alt="arrow" />
              </Link>

              <Link
                target="_blank"
                href="mailto:info@gammaprime.fi"
                sx={{ marginTop: "16px" }}
              >
                <Typography
                  variant="body1"
                  color="#39CA88"
                  letterSpacing={"-0.32px"}
                >
                  Send an email to Gamma Prime Foundation
                </Typography>
                <LazyImage src={arrow} alt="arrow" />
              </Link>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <Footer />
    </div>
  );
});

export default GetInTouch;
