import { FC, memo, useEffect, useState } from "react";
import {
	HeaderContent,
	HeaderLinks,
	HeaderLinksMobile,
	Light,
	LinkStyled,
	MobileWrapper,
	Soon,
} from "./styled";
import logo from "../../assets/svg/logo.svg";
import { PagesPath } from "../../utils/route";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../utils/use-is-mobile";
import { FlexCenteredVertically } from "../../styles/styled-component";

import { GradientLineVertical } from "../gradient-line";
import { useGetStrategiesQuery } from "../../services/gama-data";

export const headerLinks = [
	{ page: "Home", path: PagesPath.home },
	{ page: "Careers", path: PagesPath.careers },
	{ page: "About Us", path: PagesPath.aboutUs },
	{ page: "Blog", path: PagesPath.blog },
];

export const Header: FC = memo(() => {
	const isMobile = useIsMobile();
	const [isOpen, setIsOpen] = useState(false);
	const { data } = useGetStrategiesQuery(null);
	const deltaStrategy = data?.strategies.find((el) => el.slug === "delta");
	const growthStrategy = data?.strategies.find((el) => el.slug === "growth");

	const apyData = [
		{
			label: "DELTA NEUTRAL:",
			value: deltaStrategy?.apy.weekly ?? 0.0,
			color: "#D384F8",
			type: "purple",
		},
		{
			label: "GROWTH STRATEGY:",
			value: growthStrategy?.apy.weekly ?? 0.0,
			color: "#06FFE1",
			type: "green",
		},
	] as const;

	useEffect(() => {
		if (isOpen) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = "";
		}
		return () => {
			document.documentElement.style.overflow = "";
		};
	}, [isOpen]);

	const closeMenu = () => {
		setIsOpen(false);
	};

	return (
		<HeaderContent>
			<Light />
			<FlexCenteredVertically gap="50px">
				<Link style={{ zIndex: 3 }} to={PagesPath.home}>
					<img src={logo} alt="logo" />
				</Link>

				{!isMobile && (
					<>
						<HeaderLinks>
							{headerLinks.map((l) => (
								<LinkStyled key={l.page} to={l.path} onClick={closeMenu}>
									<Typography variant="body1" fontWeight={400}>
										{l.page}
									</Typography>
								</LinkStyled>
							))}

							<FlexCenteredVertically gap="4px">
								<Typography variant="body1" fontWeight={400}>
									Products & Tools
								</Typography>
								<Soon>Soon</Soon>
							</FlexCenteredVertically>
						</HeaderLinks>
					</>
				)}
			</FlexCenteredVertically>

			{isMobile && (
				<div
					className={isOpen ? "burger burger-active" : "burger"}
					style={{ zIndex: 3 }}
					onClick={() => setIsOpen(!isOpen)}
				>
					<div className="strip burger-strip-2">
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}

			{!isMobile && (
				<FlexCenteredVertically gap="36px">
					{apyData.map((el) => (
						<FlexCenteredVertically gap="12px" key={el.label}>
							<GradientLineVertical type={el.type} />
							<Box>
								<Typography fontSize={12} fontWeight="bold">
									{el.label}
								</Typography>
								<Typography fontSize={12} fontWeight="bold" color={el.color}>
									{el.value}% APY
								</Typography>
							</Box>
						</FlexCenteredVertically>
					))}

					<LinkStyled to={PagesPath.getInTouch}>
						<Typography variant="body1" fontWeight={700}>
							Get in Touch
						</Typography>
					</LinkStyled>
				</FlexCenteredVertically>
			)}

			{isMobile && isOpen && (
				<MobileWrapper>
					<HeaderLinksMobile>
						{headerLinks.map((l) => (
							<LinkStyled key={l.page} to={l.path}>
								<Typography variant="body1" fontWeight={400}>
									{l.page}
								</Typography>
							</LinkStyled>
						))}

						<FlexCenteredVertically gap="4px">
							<Typography variant="body1" fontWeight={400}>
								Products & Tools
							</Typography>
							<Soon>Soon</Soon>
						</FlexCenteredVertically>

						<LinkStyled to={PagesPath.getInTouch}>
							<Typography variant="body1" fontWeight={700}>
								Get in Touch
							</Typography>
						</LinkStyled>
					</HeaderLinksMobile>
				</MobileWrapper>
			)}
		</HeaderContent>
	);
});

Header.displayName = "Header";
